import React, { useContext } from "react"
import { useQuery, gql } from "@apollo/client"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Button } from "primereact/button"
import { useNavigate } from "react-router-dom"
import { UserContext } from "../../context/userContext"

import { LoaderMedium } from "../../components/Loaders"
import { SplitButton } from "primereact/splitbutton"

import gATracking from "../../helpers/gaTracking"
import getActualTime from "../../helpers/getActualTime"

const GET_COURSES = gql`
  query GetCourses {
    courses {
      id
      title
      description
      lessons {
        id
      }
    }
  }
`

export default function ListCourse() {
  const navigate = useNavigate()
  const userCtx = useContext(UserContext)
  const date = getActualTime()

  const { loading, error, data } = useQuery(GET_COURSES, {
    fetchPolicy: "network-only",
  })

  if (loading) return <LoaderMedium />
  if (error) return <div>Error! {error.message}</div>

  const courses = data.courses

  /*Google Analytics tracking*/
  const gaTrackingParams = {
    event: "",
    userCtx,
    payload: {},
  }
  /*End Google Analytics tracking*/

  const action = ({ id }) => {
    const items = [
      {
        label: "Edit",
        icon: "pi pi-pencil",
        command: () => {
          gaTrackingParams.event = "edit"
          gaTrackingParams.payload = {
            content_id: id,
            content_type: "Course",
            timestamp: date,
          }
          gATracking(gaTrackingParams)
          navigate(`/edit/course/${id}`)
        },
      },
    ]
    return (
      <SplitButton
        className="split_btn"
        label="Preview"
        onClick={() => navigate(`/preview/course/${id}`)}
        model={items}
      />
    )
  }

  return (
    <div>
      <div className="flex align-content-center gap-4 mb-4">
        <h1 className="mt-1">List of Courses</h1>
        <Button
          icon="pi pi-plus"
          rounded
          style={{
            backgroundColor: "var( --branded-dark-blue)",
            borderColor: "var(--branded-dark-blue)",
          }}
          onClick={() => navigate("/add/course")}
          severity="success"
          aria-label="Add"
        />
      </div>
      <DataTable
        value={courses}
        paginator
        rows={10}
        showGridlines
        resizableColumns
        scrollHeight="flex"
        className="w-full	"
        style={{ tableLayout: "fixed", maxWidth: "100%" }}
      >
        <Column
          sortable
          field="title"
          header="Title"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "50px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          field="description"
          header="Description"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          header="Number of Lessons"
          headerStyle={{ whiteSpace: "pre-line" }}
          body={(rowData) => rowData.lessons.length}
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "50px", wordWrap: "break-word" }}
        />
        <Column
          body={(row) => action(row)}
          header="Action"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "50px", wordWrap: "break-word" }}
        />
      </DataTable>
    </div>
  )
}
