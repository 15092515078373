import React from "react"
import { useQuery, gql } from "@apollo/client"
import { LoaderMedium } from "../../components/Loaders"

// Prime React
import { Card } from "primereact/card"
import { Knob } from "primereact/knob"

const GET_PARTICIPANT = gql`
  query Participant($participant_id: ID!) {
    participant(participant_id: $participant_id) {
      participant_id

      participant_assessments {
        assessment_current {
          assessment_type
          assessment_date
          total_score_personal_performance
          total_score_satisfaction_life
          total_score_resilience_stress
          total_score_assessment
        }
        assessment_diff {
          assessment_type
          assessment_date
          diff_score_personal_performance
          diff_score_satisfaction_life
          diff_score_resilience_stress
          diff_score_assessment
        }
      }
    }
  }
`

export default function ParticipantAssessmentScore(id) {
  const idParticipant = id.id
  const { loading, error, data } = useQuery(GET_PARTICIPANT, {
    variables: { participant_id: idParticipant },
    fetchPolicy: "network-only",
    skip: !idParticipant,
  })

  if (loading) return <LoaderMedium />
  if (error) return <div>Error! {error.message}</div>

  const participant = data.participant

  const getStyle = (value) => ({
    color: value >= 0 ? "green" : "red",
  })

  const dateTemplate = (dateObject) => {
    const dateText = dateObject.assessment_date
    const isoDateString = dateText.replace(" ", "T")
    const date = new Date(isoDateString)
    return date.toISOString().split("T")[0]
  }

  const assessmentInit =
    participant.participant_assessments.assessment_current[0]
  const assessmentCurrent =
    participant.participant_assessments.assessment_current[1]

  return (
    <div>
      <div className="flex justify-content-between">
        <div className="">
          <p className="-my-3">
            {" "}
            <b> Submited:</b> {dateTemplate(assessmentCurrent)}{" "}
          </p>
        </div>
      </div>

      <div className="flex gap-5 mt-5">
        <Card className="flex flex-column align-items-center justify-content-between	w-3">
          <h3 className="-mt-3 text-center">Personal Performance</h3>
          <p className="text-center m-0">Current Score</p>
          <div className="flex justify-content-between align-items-center ">
            <div className="flex flex-column ">
              <div className="flex flex-column justify-content-center align-items-center ">
                <Knob
                  value={assessmentCurrent.total_score_personal_performance}
                  readOnly
                  min={0}
                  max={72}
                  size={200}
                  strokeWidth={10}
                  valueColor="#21becb"
                  rangeColor="#c0c0c0"
                />
              </div>
              <div className="flex flex-column justify-content-center align-items-center gap-2">
                <h4 className="m-0">
                  <b>Max Score: 72 </b>
                </h4>
                <h4 className="m-0">
                  Progress: {"   "}
                  {participant.participant_assessments.assessment_diff
                    .diff_score_personal_performance === 0 ? (
                    <span style={{ color: "#3175bb" }}>No Change</span>
                  ) : (
                    <b
                      style={getStyle(
                        participant.participant_assessments.assessment_diff
                          .diff_score_personal_performance
                      )}
                    >
                      {
                        participant.participant_assessments.assessment_diff
                          .diff_score_personal_performance
                      }
                      %
                    </b>
                  )}
                </h4>

                <h4 className="m-0">
                  Initial Score:{" "}
                  <b>{assessmentInit.total_score_personal_performance}</b>
                </h4>
              </div>
            </div>
          </div>
        </Card>
        <Card className="flex flex-column align-items-center justify-content-between	w-3">
          <h3 className="-mt-3 text-center">Life Satisfaction</h3>
          <p className="text-center m-0">Current Score</p>
          <div className="flex justify-content-between align-items-center ">
            <div className="flex flex-column ">
              <div className="flex flex-column justify-content-center align-items-center ">
                <Knob
                  value={assessmentCurrent.total_score_satisfaction_life}
                  readOnly
                  min={0}
                  max={48}
                  size={200}
                  strokeWidth={10}
                  valueColor="#31b66c"
                  rangeColor="#c0c0c0"
                />
              </div>
              <div className="flex flex-column justify-content-center align-items-center gap-2">
                <h4 className="m-0">
                  <b>Max Score: 48 </b>
                </h4>
                <h4 className="m-0">
                  Progress: {"   "}
                  {participant.participant_assessments.assessment_diff
                    .diff_score_satisfaction_life === 0 ? (
                    <span style={{ color: "#3175bb" }}>No Change</span>
                  ) : (
                    <b
                      style={getStyle(
                        participant.participant_assessments.assessment_diff
                          .diff_score_satisfaction_life
                      )}
                    >
                      {
                        participant.participant_assessments.assessment_diff
                          .diff_score_satisfaction_life
                      }
                      %
                    </b>
                  )}
                </h4>

                <h4 className="m-0">
                  Initial Score:{" "}
                  <b>{assessmentInit.total_score_satisfaction_life}</b>
                </h4>
              </div>
            </div>
          </div>
        </Card>
        <Card className="flex flex-column align-items-center justify-content-between	w-3">
          <h3 className="-mt-3 text-center">Resilence to Stress</h3>
          <p className="text-center m-0">Current Score</p>
          <div className="flex justify-content-between align-items-center ">
            <div className="flex flex-column ">
              <div className="flex flex-column justify-content-center align-items-center ">
                <Knob
                  value={assessmentCurrent.total_score_resilience_stress}
                  readOnly
                  min={0}
                  max={21}
                  size={200}
                  strokeWidth={10}
                  valueColor="#1fa049"
                  rangeColor="#c0c0c0"
                />
              </div>
              <div className="flex flex-column justify-content-center align-items-center gap-2">
                <h4 className="m-0">
                  <b>Max Score: 21 </b>
                </h4>
                <h4 className="m-0">
                  Progress: {"   "}
                  {participant.participant_assessments.assessment_diff
                    .diff_score_resilience_stress === 0 ? (
                    <span style={{ color: "#3175bb" }}>No Change</span>
                  ) : (
                    <b
                      style={getStyle(
                        participant.participant_assessments.assessment_diff
                          .diff_score_resilience_stress
                      )}
                    >
                      {
                        participant.participant_assessments.assessment_diff
                          .diff_score_resilience_stress
                      }
                      %
                    </b>
                  )}
                </h4>

                <h4 className="m-0">
                  Initial Score:{" "}
                  <b>{assessmentInit.total_score_resilience_stress}</b>
                </h4>
              </div>
            </div>
          </div>
        </Card>
        <Card className="flex flex-column align-items-center justify-content-between	w-3">
          <h3 className="-mt-3 text-center">Total Assessment</h3>
          <p className="text-center m-0">Current Score</p>
          <div className="flex justify-content-between align-items-center ">
            <div className="flex flex-column ">
              <div className="flex flex-column justify-content-center align-items-center ">
                <Knob
                  value={assessmentCurrent.total_score_assessment}
                  readOnly
                  min={0}
                  max={141}
                  size={200}
                  strokeWidth={10}
                  valueColor="#1e529c"
                  rangeColor="#c0c0c0"
                />
              </div>
              <div className="flex flex-column justify-content-center align-items-center gap-2">
                <h4 className="m-0">
                  <b>Max Score: 141 </b>
                </h4>
                <h4 className="m-0">
                  Progress: {"   "}
                  {participant.participant_assessments.assessment_diff
                    .diff_score_assessment === 0 ? (
                    <span style={{ color: "#3175bb" }}>No Change</span>
                  ) : (
                    <b
                      style={getStyle(
                        participant.participant_assessments.assessment_diff
                          .diff_score_assessment
                      )}
                    >
                      {
                        participant.participant_assessments.assessment_diff
                          .diff_score_assessment
                      }
                      %
                    </b>
                  )}
                </h4>

                <h4 className="m-0">
                  Initial Score: <b>{assessmentInit.total_score_assessment}</b>
                </h4>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
}
