import React, { useEffect } from "react"

import { useFormikContext } from "formik"

// Ctx
import propTypes from "prop-types"

export default function TypeParticipantId({ field }) {
  const formik = useFormikContext()

  useEffect(() => {
    // const trigger = field.field_name
    // if (trigger === "staff_id") {
    //   return formik.setFieldValue(field.field_name, user.staff_id)
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div style={{ display: "none" }}>
      <input
        type="hidden"
        name={field.field_name}
        value={formik.values[field.field_name]}
      />
    </div>
  )
}

TypeParticipantId.propTypes = {
  field: propTypes.shape({
    field_name: propTypes.string.isRequired,
  }),
}
