const ROUTES = {
  // Main
  HOMEPAGE: "/",
  DENIED: "denied",
  ERROR: "error",

  // Form
  GLOBAL_FORM: "form/:idTemplate/:formPage",
  GLOBAL_FORM_EDIT: "form/edit/:idTemplate/:formPage/:idRef",
  REGISTRATION_FORM: "registration/:idTemplate/:formPage/:idCohort",
  THANK_YOU: "thank-you",

  // Course
  ADD_COURSE: "add/course",
  EDIT_COURSE: "edit/course/:id",
  VIEW_COURSE: "view/course/:id",
  PREVIEW_COURSE: "preview/course/:id",
  LIST_COURSES: "list/courses",

  // Lesson
  ADD_LESSON: "add/lesson",
  EDIT_LESSON: "edit/lesson/:idLesson",
  VIEW_LESSON: "view/course/:idCourse/lesson/:idLesson",
  PREVIEW_LESSON: "preview/course/:idCourse/lesson/:idLesson",
  LIST_LESSONS: "list/lessons",

  // Resource
  EDIT_RESOURCE: "edit/resource/:idResource",

  // Organization
  ADD_ORGANIZATION: "form/organization/1",
  LIST_ORGANIZATION: "list/organization",

  // Cohort
  ADD_COHORT: "form/organization_cohort/1",
  LIST_COHORT: "list/cohort",

  // THS Routes
  THS_ASSESSMENTS: "ths-admin/assessments",
  THS_COACHING_ASIGNMENTS: "list/participant",
  THS_LESSON_ENGAGEMENTS: "ths-admin/lesson-engagements",
  THS_RESOURCES: "ths-admin/resources",

  // Staff Routes
  STAFF_FORM: "form/staff/1",
  STAFF_LIST: "list/staff-user",
  STAFF_ASSESSMENTS: "staff-user/assessments",
  STAFF_COACHING: "staff-user/coaches",
  STAFF_NOTES: "staff-user/notes",
  STAFF_PROFILE: "staff-user/profile/:idStaff",
  // Participants
  PARTICIPANT_LIST: "ths-admin/coaching-asignments",
  PARTICIPANT_ASSESSMENTS: "participant/assessments",
  PARTICIPANT_COURSES: "participant/courses",
  PARTICIPANT_PROFILE: "participant/profile",
  PARTICIPANT_LIST_ALL: "list/all-participants",

  PARTICIPANT_PROFILE_VIEW: "participant/profile/:idParticipant",
}

export default ROUTES
