import React, { useState, useEffect, useRef, useContext } from "react"
import { Link, useNavigate } from "react-router-dom"
import { gql, useQuery, useMutation } from "@apollo/client"
import { UserContext } from "../../context/userContext"
// PR
import { LoaderMedium } from "../../components/Loaders"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Dropdown } from "primereact/dropdown"
import { SplitButton } from "primereact/splitbutton"
import { ConfirmDialog } from "primereact/confirmdialog"
import { Toast } from "primereact/toast"

//Ga
import gATracking from "../../helpers/gaTracking"
import getActualTime from "../../helpers/getActualTime"

export default function CoachingAssignments() {
  const GET_PARTICIPANTS = gql`
    query Participants {
      participants: participantsAwaiting {
        course_id
        organization_id
        organization_cohort_id
        organization_cohort_name
        participant_coaching_id
        participant_coaching_registration_id
        participant_email
        participant_id
        participant_improvement_focus_primary
        participant_improvement_focus_secondary
        participant_name_full
        participant_work_title
      }
      staff: staffAllActive {
        staff_id
        staff_name_full
      }
    }
  `

  const ASSIGNATION = gql`
    mutation updateParticipantCoaching(
      $participant_id: ID!
      $participant_coaching_id: ID!
      $participant_coaching_registration_id: ID!
      $organization_cohort_id: ID!
      $staff_id: ID!
      $course_id: ID!
    ) {
      updateParticipantCoaching(
        participant_id: $participant_id
        participant_coaching_id: $participant_coaching_id
        participant_coaching_registration_id: $participant_coaching_registration_id
        organization_cohort_id: $organization_cohort_id
        staff_id: $staff_id
        course_id: $course_id
      ) {
        data
        message
        error
      }
    }
  `
  const DELETE_PARTICIPANT = gql`
    mutation deleteParticipant($participant_id: ID!) {
      deleteParticipant(participant_id: $participant_id) {
        message
      }
    }
  `
  const {
    loading,
    error,
    data,
    refetch: refetchParticipants,
  } = useQuery(GET_PARTICIPANTS, {
    fetchPolicy: "network-only",
  })

  const [deleteParticipant, { data: deleteData, error: deleteError }] =
    useMutation(DELETE_PARTICIPANT)

  const [updateParticipantCoaching, { error: mutationError }] = useMutation(
    ASSIGNATION,
    {
      onCompleted: (data) => {
        refetchParticipants()
        console.log("Coach assigned", data)
      },
    }
  )
  const userCtx = useContext(UserContext)
  const date = getActualTime()
  const [selectedCohort, setSelectedCohort] = useState(null)
  const [selectedParticipant, setSelectedParticipant] = useState(null)
  const [showDialog, setShowDialog] = useState(false)

  const navigate = useNavigate()
  const toast = useRef(null)

  useEffect(() => {
    if (deleteData?.deleteParticipant?.message) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: deleteData.deleteParticipant.message,
        life: 3000,
      })
    } else if (deleteError) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: mutationError.message,
        life: 3000,
      })
    }
  }, [deleteData, deleteError])

  if (loading) return <LoaderMedium />
  if (error) return <div>Error! {error.message}</div>

  const participants = data.participants || []

  /*Google Analytics tracking*/
  const gaTrackingParams = {
    event: "button_click",
    userCtx,
    payload: {},
  }
  /*End Google Analytics tracking*/

  const action = (rowData) => {
    const { participant_id } = rowData
    const items = [
      {
        label: "Delete",
        command: () => {
          gaTrackingParams.event = "deleted"
          gaTrackingParams.payload = {
            organization_id: rowData.organization_id,
            content_id: participant_id,
            content_type: "participant",
            timestamp: date,
          }
          gATracking(gaTrackingParams)

          deleteParticipant({
            variables: { participant_id },
            refetchQueries: [{ query: GET_PARTICIPANTS }],
          })
        },
      },
    ]
    return (
      <SplitButton
        label="Edit"
        model={items}
        onClick={() => {
          gaTrackingParams.event = "edit"
          gaTrackingParams.payload = {
            organization_id: rowData.organization_id,
            content_id: participant_id,
            content_type: "participant",
            timestamp: date,
          }
          gATracking(gaTrackingParams)

          navigate(`/form/edit/profile/1/${participant_id}`)
        }}
      />
    )
  }

  const cohortsfiltered = [
    { name: "All" },
    ...Array.from(
      new Set(
        participants.map((participant) => participant.organization_cohort_name)
      )
    ).map((cohortName) => ({ name: cohortName })),
  ]

  const filteredParticipants =
    selectedCohort && selectedCohort.name !== "All"
      ? participants.filter(
          (participant) =>
            participant.organization_cohort_name === selectedCohort.name
        )
      : participants

  const nameTemplate = (rowData) => {
    return (
      <Link
        style={{
          color: "var( --branded-light-green",
          cursor: "pointer",
          textDecoration: "underline",
        }}
        onClick={() => {
          gaTrackingParams.payload = {
            button_name: "click_view_name",
            button_text: rowData.participant_name_full,
            button_type: "link_click",
            button_location: "/ths-admin/coaching-asignments",
          }
          gATracking(gaTrackingParams)
        }}
        to={`/participant/profile/${rowData.participant_id}`}
      >
        {rowData.participant_name_full}
      </Link>
    )
  }

  const handleAssign = (row, staff_id) => {
    setSelectedParticipant({
      ...row,
      staff_id,
    })
    setShowDialog(true)
  }

  const onConfirm = () => {
    updateParticipantCoaching({
      variables: {
        participant_id: selectedParticipant.participant_id,
        participant_coaching_id: selectedParticipant.participant_coaching_id,
        participant_coaching_registration_id:
          selectedParticipant.participant_coaching_registration_id,
        organization_cohort_id: selectedParticipant.organization_cohort_id,
        staff_id: selectedParticipant.staff_id,
        course_id: selectedParticipant.course_id,
      },
    })
    setShowDialog(false)
  }

  const onCancel = () => {
    setShowDialog(false)
  }

  const rowAssignation = (row) => {
    return (
      <Dropdown
        options={data.staff.map((staff) => ({
          label: staff.staff_name_full,
          value: staff.staff_id,
        }))}
        onChange={({ value }) => handleAssign(row, value)}
      />
    )
  }

  return (
    <div className="p-col-12 p-md-3">
      <h2>Unassigned Participants</h2>
      <Toast ref={toast} />

      <Dropdown
        value={selectedCohort}
        onChange={(e) => setSelectedCohort(e.value)}
        options={cohortsfiltered}
        optionLabel="name"
        placeholder="Select a Cohort"
        className="w-full md:w-14rem mb-5"
      />

      <DataTable
        value={filteredParticipants}
        columnResizeMode="fit"
        paginator
        rows={10}
        resizableColumns
        showGridlines
        scrollHeight="flex"
        className="w-full	"
        style={{ tableLayout: "fixed", maxWidth: "100%" }}
      >
        <Column
          sortable
          field="organization_cohort_name"
          header="Cohort"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "250px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          field="participant_name_full"
          header="Name"
          body={nameTemplate}
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "250px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          field="participant_email"
          header="Email"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "225px", wordWrap: "break-word" }}
        />

        <Column
          sortable
          field="participant_work_title"
          header="Title"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "150px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          field="participant_improvement_focus_primary"
          header="Primary Focus"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "175px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          field="participant_improvement_focus_secondary"
          header="Secondary Focus"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
        <Column
          body={rowAssignation}
          header="Coach Assignment"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
        <Column
          body={(rowData) => action(rowData)}
          header="Action"
          className="white-space-normal overflow-hidden"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
      </DataTable>

      <ConfirmDialog
        visible={showDialog}
        onHide={() => setShowDialog(false)}
        message="Are you sure you want to assign a coach to this participant?"
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={onConfirm}
        reject={onCancel}
      />
    </div>
  )
}
