import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"

import "./styles/global.css"

//theme
import "primeicons/primeicons.css"
import "primeflex/primeflex.css"
import "primereact/resources/primereact.min.css"
import "primereact/resources/themes/lara-light-indigo/theme.css"

import "./styles/rewrites.css"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
