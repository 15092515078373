import React from "react"
import { gql, useQuery } from "@apollo/client"
import { useParams, useNavigate } from "react-router-dom"
import { ListBox } from "primereact/listbox"

import { LoaderMedium } from "../../components/Loaders"

const GET_COURSE = gql`
  query GetCourse($id: ID!) {
    course(id: $id) {
      title
      description
      lessons {
        id
        title
        description
      }
    }
  }
`

function PreviewCourse() {
  const navigate = useNavigate()

  const { id: courseId } = useParams()
  const { loading, error, data } = useQuery(GET_COURSE, {
    variables: { id: courseId },
    fetchPolicy: "network-only",
  })

  if (loading) return <LoaderMedium />
  if (error) return <div>Error! {error.message}</div>

  const courseList = data.course.lessons.map((lesson) => {
    return {
      name: lesson.title,
      id: lesson.id,
    }
  })

  return (
    <div>
      <h1>{data.course.title}</h1>
      <p>{data.course.description}</p>
      <ListBox
        onChange={({ target: { value } }) =>
          navigate(`/preview/course/${courseId}/lesson/${value.id}`)
        }
        options={courseList}
        optionLabel="name"
        className="w-24rem"
      />
    </div>
  )
}

export default PreviewCourse
