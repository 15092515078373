import React, { useState } from "react"
import _ from "lodash"
import { useNavigate, useParams } from "react-router-dom"
import { gql, useQuery, useMutation } from "@apollo/client"

// PR
import { Button } from "primereact/button"
import { Chips } from "primereact/chips"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { Divider } from "primereact/divider"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"

// Helpers
import convertAvgTime from "../../helpers/convertAvgTime"
import statusBodyTemplate from "./helpers/statusBodyTemplate"

// Comps
import AddResource from "./components/AddResource"

import { LoaderMedium } from "../../components/Loaders"

import propTypes from "prop-types"
const GET_LESSON = gql`
  query GetLesson($id: ID!) {
    lesson(id: $id) {
      id
      title
      description
      tags
      resources {
        id
        name
        avgTime
        shared
        type
        content {
          description
          htmlTag
          title
          value
          gs
        }
      }
    }
  }
`
const EDIT_LESSON = gql`
  mutation EditLesson(
    $id: ID!
    $title: String!
    $description: String
    $tags: [String]
    $resources: [ResourceInput]
  ) {
    editLesson(
      id: $id
      title: $title
      description: $description
      tags: $tags
      resources: $resources
    ) {
      id
    }
  }
`
const ActionButtons = ({ rowData, onDelete, onEdit }) => {
  return (
    <div className="flex justify-content-center gap-2">
      <Button
        icon="pi pi-trash"
        className="p-button-danger"
        onClick={() => onDelete(rowData)}
      />
      <Button
        icon="pi pi-pencil"
        className="p-button-primary"
        onClick={() => onEdit(rowData)}
      />
    </div>
  )
}

ActionButtons.propTypes = {
  rowData: propTypes.object.isRequired,
  onDelete: propTypes.func.isRequired,
  onEdit: propTypes.func.isRequired,
}

export default function EditLesson() {
  const { idLesson } = useParams()

  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [tags, setTags] = useState([])
  const [id, setId] = useState(0)

  const [editMode, setEditMode] = useState(false)
  const [editResource, setEditResource] = useState({})

  const [resources, setResources] = useState([])

  const navigate = useNavigate()

  const { loading, error } = useQuery(GET_LESSON, {
    variables: { id: idLesson },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      console.log("Lesson data: ", data)
      setTitle(data.lesson.title)
      setDescription(data.lesson.description)
      setTags(data.lesson.tags)
      setResources(data.lesson.resources)
    },
  })

  const [editLesson] = useMutation(EDIT_LESSON, {
    variables: {
      id: idLesson,
      title,
      tags,
      description,
      resources,
    },
    onCompleted: (data) => {
      navigate("/list/lessons")
    },
  })

  if (loading) return <LoaderMedium />
  if (error) return <div>Error! {error.message}</div>

  const handleAddResource = (newResource) => {
    setId(id + 1)
    newResource.id = `new_${id}`
    setResources([...resources, newResource])
  }

  const handleEditResource = (newResource, initialResourceId) => {
    const updatedResources = resources.map((resource) =>
      resource.id === initialResourceId ? newResource : resource
    )
    setResources(updatedResources)
    setEditMode(false)
  }

  const editRow = (rowData) => {
    setEditMode(true)
    setEditResource(rowData)
    // Scroll the AddResource section into view
    const addResourceSection = document.getElementById("addResourceSection")
    if (addResourceSection) {
      addResourceSection.scrollIntoView({ behavior: "smooth" })
    }
  }

  const deleteRow = (rowData) => {
    const updatedResources = resources.filter(
      (resource) => resource.id !== rowData.id
    )
    setResources(updatedResources)
  }

  const renderActionButtons = (rowData) => (
    <ActionButtons rowData={rowData} onDelete={deleteRow} onEdit={editRow} />
  )

  return (
    <div>
      <h1>Edit Lesson</h1>
      <div className="flex flex-column gap-4 mb-5">
        <div className="flex flex-column">
          <label htmlFor="title">Title</label>
          <InputText
            id="title"
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>

        <div className="flex flex-column">
          <label htmlFor="input">Description</label>
          <InputTextarea
            id="description"
            name="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows={5}
            cols={30}
          />
        </div>
        <div className="flex flex-column">
          <label htmlFor="tags">Tags</label>
          <Chips
            value={tags}
            onChange={(e) => setTags(e.value)}
            separator=","
          />
          <small>Comma separated tags.</small>
        </div>
      </div>

      <Button
        label="Save"
        className="p-button-success"
        onClick={editLesson}
        disabled={
          _.isEmpty(title) || _.isEmpty(description) || _.isEmpty(resources)
        }
      />

      <Divider id="addResourceSection" />

      <AddResource
        handleAddResource={handleAddResource}
        handleEditResource={handleEditResource}
        editMode={editMode}
        editResource={editResource}
        setEditMode={setEditMode}
      />

      <Divider />

      <h2>Resource List</h2>

      <DataTable
        value={resources}
        dataKey="id"
        reorderableColumns
        reorderableRows
        tableStyle={{ minWidth: "50rem" }}
        onRowReorder={(e) => {
          setResources([...e.value])
        }}
      >
        <Column rowReorder style={{ width: "3rem" }} />
        <Column field="name" header="Name" style={{ width: "50%" }} />
        <Column
          field="type"
          header="Type"
          body={statusBodyTemplate}
          style={{ width: "20%" }}
        />
        <Column
          field="avgTime"
          header="Avg time"
          body={(rowData) => convertAvgTime(rowData.avgTime)}
          style={{ width: "20%" }}
        />
        <Column
          header="Actions"
          headerStyle={{ width: "10%", minWidth: "8rem" }}
          bodyStyle={{ textAlign: "center" }}
          body={renderActionButtons}
        />
      </DataTable>
    </div>
  )
}
