import React, { useContext, useRef, useEffect } from "react"
import { useLocation, useNavigate, Link } from "react-router-dom"
import _ from "lodash"

import { UserContext } from "../../../context/userContext"
import { useQuery, useLazyQuery, gql } from "@apollo/client"
import { LoaderMedium } from "../../../components/Loaders"
import { Toast } from "primereact/toast"
import ParticipantAssessmentScore from "../../Participants/ParticipantAssessmentScore"
import { DateTime } from "luxon"

// Prime React
import { Button } from "primereact/button"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Divider } from "primereact/divider"

//GA
import gATracking from "../../../helpers/gaTracking"

const GET_PARTICIPANT = gql`
  query ParticipantHomepage($participant_id: ID!) {
    log: participantLessonLogByParticipantId(participant_id: $participant_id) {
      course_id
      course_name
      course_lesson_id
      course_lesson_name
      course_lesson_complete
      course_lesson_reflection
      date_created
    }
    participant(participant_id: $participant_id) {
      participant_id
      course_id
      course_name
      participant_assessments {
        assessment_current {
          assessment_date
          total_score_personal_performance
          total_score_satisfaction_life
          total_score_resilience_stress
          total_score_assessment
        }
        assessment_log {
          assessment_name
          participant_id
          assessment_date
          total_score_personal_performance
          total_score_satisfaction_life
          total_score_resilience_stress
          total_score_assessment
        }
      }
    }
  }
`

const GET_COURSE = gql`
  query GetCourse($id: ID!) {
    course(id: $id) {
      title
      description
      lessons {
        id
        title
      }
    }
  }
`

export default function AuthUserHome() {
  const idParticipant = useContext(UserContext)
  const toast = useRef(null)
  const navigate = useNavigate()
  const location = useLocation()
  const hasLoggedRef = useRef(false)
  const userCtx = useContext(UserContext)

  const [getCourse, { loading: courseLoading, data: courseData }] =
    useLazyQuery(GET_COURSE)

  const { loading, error, data } = useQuery(GET_PARTICIPANT, {
    variables: { participant_id: idParticipant.session_id },
    fetchPolicy: "network-only",
    skip: !idParticipant.session_id,
    onCompleted: async (data) => {
      const courseId = data.participant?.course_id || null
      await getCourse({
        skip: !courseId,
        variables: { id: courseId },
      })
    },
  })
  const gaTrackingParams = {
    event: "",
    userCtx,
    payload: {},
  }

  useEffect(() => {
    if (location.state?.fromLogin && !hasLoggedRef.current) {
      gaTrackingParams.event = "login"

      gATracking(gaTrackingParams)

      hasLoggedRef.current = true
    }
  }, [location.state])

  if (loading || courseLoading) return <LoaderMedium />
  if (error) return <div>Error! {error.message}</div>

  const participant = data.participant

  const dateTemplate = (dateObject) => {
    const dateText = dateObject.assessment_date
    const isoDateString = dateText.replace(" ", "T")
    const date = new Date(isoDateString)
    return date.toISOString().split("T")[0]
  }

  const handleTopData = () => {
    const lessonsCompletedList = data.log
      ? data.log.reduce((acc, { course_id, course_lesson_id }) => {
          if (course_id === data.participant?.course_id) {
            return [...acc, course_lesson_id]
          }
          return acc
        }, [])
      : []

    const getNextLesson = () => {
      const courseId = data.participant?.course_id
      const currentCourseLessons = courseData?.course?.lessons || []

      let firstNonCompleted = {}

      for (const element of currentCourseLessons) {
        if (!lessonsCompletedList.includes(element.id)) {
          firstNonCompleted = element
          break // Exit the loop as soon as we find the first non-completed value
        }
      }

      const allLessonsCompleted =
        lessonsCompletedList.length === currentCourseLessons.length

      const nextLessonLink = `/view/course/${courseId}/lesson/${firstNonCompleted?.id}`

      return (
        <div className="col-12 md:col-6 lg:col-3">
          <div className="surface-0 shadow-1 p-3 border-1 border-50 border-round h-9rem">
            <div className="flex justify-content-between mb-3">
              <div>
                <span className="block text-500 font-medium mb-3">
                  What's Next?
                </span>
                <div className="text-900 font-medium text-xl">
                  {allLessonsCompleted ? (
                    "🎉 Course Completed"
                  ) : (
                    <Link to={nextLessonLink}>{firstNonCompleted.title}</Link>
                  )}
                </div>
              </div>
              <div
                className="flex align-items-center justify-content-center bg-purple-100 border-round"
                style={{ width: "2.5rem", height: "2.5rem" }}
              >
                <i className="pi pi-caret-right text-purple-500 text-xl"></i>
              </div>
            </div>
          </div>
        </div>
      )
    }

    const getLastCompletedLesson = () => {
      let lastCompletedLesson = {}

      const latestLessonList = data?.log
        ? data.log.filter(
            ({ course_id }) => course_id === data.participant?.course_id
          )
        : []

      if (latestLessonList.length > 0) {
        const latestLesson = latestLessonList[0]
        const title = latestLesson?.course_lesson_name || "None"
        const date = latestLesson?.date_created
          ? DateTime.fromFormat(
              latestLesson?.date_created,
              "yyyy-MM-dd HH:mm:ss"
            ).toLocaleString(DateTime.DATE_MED)
          : "None"
        const link = `/view/course/${latestLesson?.course_id}/lesson/${latestLesson?.course_lesson_id}`
        lastCompletedLesson = { title, date, link }
      }
      return (
        <div className="col-12 md:col-6 lg:col-3">
          <div className="surface-0 shadow-1 p-3 border-1 border-50 border-round h-9rem">
            <div className="flex justify-content-between mb-3">
              <div>
                <span className="block text-500 font-medium mb-3">
                  Last Completed Lesson
                </span>
                <div className="text-900 font-medium text-l">
                  {!_.isEmpty(lastCompletedLesson) ? (
                    <Link to={lastCompletedLesson.link}>
                      {lastCompletedLesson.title}
                    </Link>
                  ) : (
                    "Not started"
                  )}
                </div>
              </div>
              <div
                className="flex align-items-center justify-content-center bg-cyan-100 border-round"
                style={{ width: "2.5rem", height: "2.5rem" }}
              >
                <i className="pi pi-star text-cyan-500 text-xl"></i>
              </div>
            </div>
            <span className="text-green-500 font-medium">
              {lastCompletedLesson.date}
            </span>
          </div>
        </div>
      )
    }

    const getCurrentCourse = () => {
      const courseName = data.participant.course_name || "No Course Selected"
      const courseId = data.participant.course_id
      const link = `/view/course/${courseId}`
      return (
        <div className="col-12 md:col-6 lg:col-3">
          <div className="surface-0 shadow-1 p-3 border-1 border-50 border-round h-9rem">
            <div className="flex justify-content-between mb-3">
              <div>
                <span className="block text-500 font-medium mb-3">
                  Current Courses
                </span>
                <div className="text-900 font-medium text-xl">
                  <Link to={link}>{courseName}</Link>
                </div>
              </div>
              <div
                className="flex align-items-center justify-content-center bg-blue-100 border-round"
                style={{ width: "2.5rem", height: "2.5rem" }}
              >
                <i className="pi pi-flag text-blue-500 text-xl" />
              </div>
            </div>
          </div>
        </div>
      )
    }

    const getCompletedLessons = () => {
      return (
        <div className="col-12 md:col-6 lg:col-3">
          <div className="surface-0 shadow-1 p-3 border-1 border-50 border-round h-9rem">
            <div className="flex justify-content-between mb-3">
              <div>
                <span className="block text-500 font-medium mb-3">
                  Completed Lessons
                </span>
                <div className="text-900 font-medium text-xl">
                  {lessonsCompletedList.length || 0}
                </div>
              </div>

              <div
                className="flex align-items-center justify-content-center bg-orange-100 border-round"
                style={{ width: "2.5rem", height: "2.5rem" }}
              >
                <i className="pi pi-th-large text-orange-500 text-xl" />
              </div>
            </div>

            <span className="text-green-500 font-medium">
              Total Lessons: {courseData?.course?.lessons?.length || 0}
            </span>
          </div>
        </div>
      )
    }

    return (
      <div className="grid">
        {getNextLesson()}
        {getLastCompletedLesson()}
        {getCurrentCourse()}
        {getCompletedLessons()}
      </div>
    )
  }

  return (
    <div>
      <Toast ref={toast} />

      <div>
        <h1>Homepage</h1>
        {handleTopData()}
        <Divider />
        <div className="flex justify-content-between">
          <h2 className="mt-5">Current Assessment</h2>
          <Button
            id="TakeAssessmentBtn"
            className="my-3"
            label="Take Assessment"
            onClick={(e) => {
              const buttonId = e.target.id

              gaTrackingParams.event = "button_click"
              gaTrackingParams.payload = {
                button_id: buttonId,
                button_text: "Take Assessment",
                button_type: "Button",
                button_location: "Participant Home",
              }
              gATracking(gaTrackingParams)
              navigate("/?assessment=active")
            }}
          />
        </div>

        <div className="flex flex-column flex-wrap gap-5">
          {participant?.participant_assessments.assessment_current?.length >
          1 ? (
            <ParticipantAssessmentScore id={idParticipant.session_id} />
          ) : (
            <div>
              <div className="flex justify-content-between align-items-center">
                <h3>Select Take Assessment to begin!</h3>
                <Button
                  id="TakeFirstAssessmentBtn"
                  style={{ marginTop: "-8rem" }}
                  label="Take Assessment"
                  onClick={(e) => {
                    const buttonId = e.target.id

                    gaTrackingParams.event = "button_click"
                    gaTrackingParams.payload = {
                      button_id: buttonId,
                      button_text: "Take Assessment",
                      button_type: "Button",
                      button_location: "Participant Home",
                    }
                    gATracking(gaTrackingParams)
                    navigate("/?assessment=active")
                  }}
                />
              </div>
            </div>
          )}
        </div>

        <hr className="my-5" />
        <h1>My Assessments</h1>
        <div className="flex flex-column flex-wrap gap-5">
          <DataTable
            paginator
            rows={10}
            value={participant.participant_assessments.assessment_log}
            resizableColumns
            showGridlines
            scrollHeight="flex"
            className="w-full"
            style={{ tableLayout: "fixed", maxWidth: "100%" }}
          >
            <Column
              sortable
              field="assessment_name"
              header="Assessment Name"
              headerClassName="text-center"
              style={{ maxWidth: "75px", wordWrap: "break-word" }}
            />

            <Column
              field="assessment_date"
              body={dateTemplate}
              header="Date"
              headerClassName="text-center"
              style={{ maxWidth: "50px", wordWrap: "break-word" }}
            />

            <Column
              className="text-center"
              field="total_score_personal_performance"
              header="Personal Performance"
              headerClassName="text-center"
              style={{ maxWidth: "57.5px", wordWrap: "break-word" }}
            />
            <Column
              className="text-center"
              field="total_score_satisfaction_life"
              header="Life Satisfaction"
              headerClassName="text-center"
              style={{ maxWidth: "50px", wordWrap: "break-word" }}
            />
            <Column
              className="text-center"
              field="total_score_resilience_stress"
              header="Resilence to Stress"
              headerClassName="text-center"
              style={{ maxWidth: "50px", wordWrap: "break-word" }}
            />
            <Column
              className="text-center"
              field="total_score_assessment"
              header="Total Score"
              headerClassName="text-center"
              style={{ maxWidth: "50px", wordWrap: "break-word" }}
            />
          </DataTable>
        </div>
      </div>
    </div>
  )
}
