import React from "react"
import { TabView, TabPanel } from "primereact/tabview"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"

export default function SysAdminHome() {
  const coaches = [
    { name: "John Doe", expertise: "Cognitive Behavioral Techniques" },
    { name: "Jane Smith", expertise: "Mindfulness and Meditation Practices" },
    {
      name: "Alex Johnson",
      expertise: "Stress Management and Emotional Regulation",
    },
  ]
  const orgs = [
    { name: "Deloitte", status: "Active" },
    { name: "Accenture", status: "Inactive" },
    { name: "PwC", status: "Active" },
  ]
  const courses = [
    { name: "Resilience to Stress", lessons: "7" },
    { name: "Fortifying the Mind", lessons: "4" },
    { name: "Bouncing Back", lessons: "9" },
  ]

  return (
    <div className="p-grid p-fluid">
      <h1>Workplace Well-Being</h1>
      <div className="grid">
        <div className="col-12 md:col-6 lg:col-3 mb-5">
          <div className="surface-0 shadow-1 p-3 border-1 border-50 border-round">
            <div className="flex justify-content-between mb-3">
              <div>
                <span className="block text-500 font-medium mb-3">
                  Total Courses
                </span>
                <div className="text-900 font-medium text-xl">12</div>
              </div>
              <div
                className="flex align-items-center justify-content-center bg-blue-100 border-round"
                style={{ width: "2.5rem", height: "2.5rem" }}
              >
                <i className="pi pi-info-circle text-blue-500 text-xl"></i>
              </div>
            </div>
            <span className="text-green-500 font-medium">2 new </span>
            <span className="text-500">since last visit</span>
          </div>
        </div>
        <div className="col-12 md:col-6 lg:col-3">
          <div className="surface-0 shadow-1 p-3 border-1 border-50 border-round">
            <div className="flex justify-content-between mb-3">
              <div>
                <span className="block text-500 font-medium mb-3">
                  Organizations
                </span>
                <div className="text-900 font-medium text-xl">116</div>
              </div>
              <div
                className="flex align-items-center justify-content-center bg-orange-100 border-round"
                style={{ width: "2.5rem", height: "2.5rem" }}
              >
                <i className="pi pi-th-large text-orange-500 text-xl"></i>
              </div>
            </div>
            <span className="text-green-500 font-medium">%12+ </span>
            <span className="text-500">since last week</span>
          </div>
        </div>
        <div className="col-12 md:col-6 lg:col-3">
          <div className="surface-0 shadow-1 p-3 border-1 border-50 border-round">
            <div className="flex justify-content-between mb-3">
              <div>
                <span className="block text-500 font-medium mb-3">
                  Registrations
                </span>
                <div className="text-900 font-medium text-xl">281</div>
              </div>
              <div
                className="flex align-items-center justify-content-center bg-cyan-100 border-round"
                style={{ width: "2.5rem", height: "2.5rem" }}
              >
                <i className="pi pi-users text-cyan-500 text-xl"></i>
              </div>
            </div>
            <span className="text-green-500 font-medium">13 </span>
            <span className="text-500">newly registered</span>
          </div>
        </div>
        <div className="col-12 md:col-6 lg:col-3">
          <div className="surface-0 shadow-1 p-3 border-1 border-50 border-round">
            <div className="flex justify-content-between mb-3">
              <div>
                <span className="block text-500 font-medium mb-3">
                  Assignations
                </span>
                <div className="text-900 font-medium text-xl">
                  152 Completed
                </div>
              </div>
              <div
                className="flex align-items-center justify-content-center bg-purple-100 border-round"
                style={{ width: "2.5rem", height: "2.5rem" }}
              >
                <i className="pi pi-share-alt text-purple-500 text-xl"></i>
              </div>
            </div>
            <span className="text-green-500 font-medium">85 </span>
            <span className="text-500">responded</span>
          </div>
        </div>
      </div>

      {/* List of Coaches */}
      <div className="p-col-12 p-md-3">
        <TabView>
          <TabPanel header="Coaches">
            <DataTable value={coaches} columnResizeMode="expand">
              <Column field="name" header="Name"></Column>
              <Column field="expertise" header="Expertise"></Column>
            </DataTable>
          </TabPanel>
          <TabPanel header="Organization List">
            <DataTable value={orgs} columnResizeMode="expand">
              <Column field="name" header="Name"></Column>
              <Column field="status" header="Status"></Column>
            </DataTable>
          </TabPanel>
          <TabPanel header="Running Courses">
            <DataTable value={courses} columnResizeMode="expand">
              <Column field="name" header="Name"></Column>
              <Column field="lessons" header="Lessons"></Column>
            </DataTable>
          </TabPanel>
        </TabView>
      </div>
    </div>
  )
}
