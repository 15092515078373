import React from "react"

export default function index() {
  return (
    <div>
      <h1>Your registration is now complete!</h1>
      <p>
        Your account has been created for you using your email address and
        password.
      </p>
      <p>
        Please look for a confirmation email with instructions for completing
        your well-being assessment.
      </p>
    </div>
  )
}
