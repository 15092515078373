import * as Yup from "yup"
import _ from "lodash"

export default function validation_conditional_dropdown(field, schema) {
  const { field_name, field_validation } = field

  const conditions = JSON.parse(field.field_conditional)
  const conditionFieldName = Object.keys(conditions)[0]

  const checkBool = () => {
    if (!field_validation?.required) return false
    return field_validation.required[0] === "true"
  }

  const isRequired = checkBool()
  const isRequiredTxt = field_validation?.required?.[1] || "Required field."

  if (!isRequired) return schema

  const conditionalValidation = Yup.string()
    .nullable()
    .when(conditionFieldName, {
      is: (value) => {
        if (_.isArray(value)) {
          return _.some(value, (v) =>
            _.includes(conditions[conditionFieldName], v)
          )
        } else {
          return _.includes(conditions[conditionFieldName], value)
        }
      },
      then: Yup.string().required(isRequiredTxt),
    })

  return schema.shape({
    [field_name]: conditionalValidation,
  })
}
