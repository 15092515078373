import { useState, useEffect } from "react"
import { useQuery, useLazyQuery, gql } from "@apollo/client"
import { DateTime } from "luxon"
import yupValidationSchema from "../helpers/yupValidationSchema"

const INIT_FORMIK = gql`
  query InitFormik($idTemplate: ID!, $page: Int) {
    template(idTemplate: $idTemplate, page: $page) {
      blocks {
        fields {
          idField
          field_name
          field_type
          field_default_value
          field_fetchable
          field_validation {
            required
          }
          field_conditional
        }
      }
    }
  }
`

const EMPTY_QUERY = gql`
  query {
    __typename
  }
`

const TEMPLATE_DICT = {
  staff: {
    queryType: "staff",
    varName: "staff_id",
  },
  coach_profile: {
    queryType: "staff",
    varName: "staff_id",
  },
  participant: {
    queryType: "participant",
    varName: "participant_id",
  },
  profile: {
    queryType: "participant",
    varName: "participant_id",
  },
  organization: {
    queryType: "organization",
    varName: "organization_id",
  },
  organization_cohort: {
    queryType: "organizationCohort",
    varName: "organization_cohort_id",
  },
  note: {
    queryType: "coachingNote",
    varName: "note_coaching_id",
  },
}

const buildDynamicQuery = (args) => {
  const { queryType, varName, fields } = args
  const filteredFields = fields.filter((field) => field.field_fetchable)
  const fieldNames = filteredFields.map((field) => field.field_name).join("\n")
  return gql`
    query StoredValues($idRef: ID!) {
      ${queryType}(${varName}: $idRef) {
        ${fieldNames}
      }
    }
  `
}

export default function useFormikInit(idTemplate, formPage, idRef) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [initialValues, setInitialValues] = useState({})
  const [storedValues, setStoredValues] = useState({})
  const [validationSchema, setValidationSchema] = useState(null)
  const [dynamicQuery, setDynamicQuery] = useState(EMPTY_QUERY)

  useEffect(() => {
    return () => {
      // Reset the form values on unmount
      setInitialValues({})
    }
  }, [formPage])

  const { loading: initLoading, error: initError } = useQuery(INIT_FORMIK, {
    variables: { page: Number(formPage), idTemplate },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (!data?.template?.blocks?.[0]?.fields) {
        setError(new Error("Invalid template data"))
        setLoading(false)
        return
      }
      const fields = data.template.blocks[0].fields || []
      setInitialValues(handleInitialValues(fields))
      setValidationSchema(yupValidationSchema(fields, idTemplate))
      // If the idRef is present, fetch the dynamic data
      if (idRef) {
        handleFetchValues(fields)
      } else {
        setLoading(false)
      }
    },
    onError: (err) => {
      setError(err)
      setLoading(false)
    },
  })

  const [fetchDynamicData, { error: dynamicError }] = useLazyQuery(
    dynamicQuery,
    {
      fetchPolicy: "no-cache",
      skip: !idRef,
      onCompleted: (data) => {
        const queryType = TEMPLATE_DICT[idTemplate]?.queryType
        if (queryType && data?.[queryType]) {
          const fetchedData = data[queryType]
          // Convert ISO 8601 date strings to JavaScript Date objects
          for (const key in fetchedData) {
            if (
              typeof fetchedData[key] === "string" &&
              /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(
                fetchedData[key]
              )
            ) {
              fetchedData[key] = DateTime.fromISO(fetchedData[key]).toJSDate()
            }
          }
          setStoredValues(fetchedData)
          setLoading(false)
        } else {
          setError(new Error("Invalid dynamic data"))
          setLoading(false)
        }
      },
      onError: (err) => {
        setError(err)
        setLoading(false)
      },
    }
  )

  const handleFetchValues = (fields) => {
    const template = TEMPLATE_DICT[idTemplate]
    if (!template) {
      setError(new Error("Invalid template type"))
      setLoading(false)
      return
    }
    const queryParams = {
      queryType: template.queryType,
      varName: template.varName,
      fields,
    }
    const query = buildDynamicQuery(queryParams)
    setDynamicQuery(query)
    fetchDynamicData({ variables: { idRef } })
  }

  const handleInitialValues = (fields) => {
    const initialValues = {}
    fields.forEach((field) => {
      initialValues[field.field_name] = field.field_default_value
    })
    return initialValues
  }

  useEffect(() => {
    if (initError) {
      setError(initError)
      setLoading(false)
    }
  }, [initError])

  useEffect(() => {
    if (dynamicError) {
      setError(dynamicError)
      setLoading(false)
    }
  }, [dynamicError])

  return {
    loading: loading || initLoading,
    error,
    initialValues,
    storedValues,
    validationSchema,
  }
}
