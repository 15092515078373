import * as Yup from "yup"

export default function validation_number(field, schema) {
  const isRequired = field.field_validation?.required?.[0] === "true"
  const requiredTxt = field.field_validation?.required?.[1]

  // Create the base number schema with min validation to avoid 0
  let numberSchema = Yup.number().min(1, "Value must be greater than 0")

  // Add required validation if isRequired is true
  if (isRequired) {
    numberSchema = numberSchema.required(requiredTxt || "Required")
  }

  // Return the updated schema with the field name and validation rules
  return schema.shape({
    [field.field_name]: numberSchema,
  })
}
