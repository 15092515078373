import React from "react"
import { useParams } from "react-router-dom"
import { Formik, Form as FormWrapper } from "formik"

// Ctx

// Loaders
import { LoaderMedium } from "../../components/Loaders"

// Hooks
import useFormikInit from "./hooks/useFormikInit"

// Components
import BlockBuilder from "./components/BlockBuilder"

export default function FormContainer() {
  const { idTemplate, formPage, idRef } = useParams()

  const { loading, error, initialValues, storedValues, validationSchema } =
    useFormikInit(idTemplate, formPage, idRef)

  const formikValues = {
    ...initialValues,
    ...storedValues,
  }

  if (loading) return <LoaderMedium />
  if (error) return <div>Error in Form Containter</div>

  const key = `${idTemplate}-block-${formPage}-${Date.now()}` // triggers re-render and propagate values.

  return (
    <Formik
      key={key}
      initialValues={formikValues}
      validationSchema={validationSchema}
      validateOnMount={true}
    >
      <FormWrapper>
        <BlockBuilder />
      </FormWrapper>
    </Formik>
  )
}
