import React from "react"

// CONST
import ROUTES from "./routesConstants"

// Pages
import Home from "../../pages/Home"
import Denied from "../../pages/Denied"
import ErrorPage from "../../pages/Error"

import * as forms from "./PageGroups/forms"
import * as courses from "./PageGroups/courses"
import * as lessons from "./PageGroups/lessons"
import * as resources from "./PageGroups/resources"
import * as staff from "./PageGroups/staff"
import * as participants from "./PageGroups/participants"
import * as organization from "./PageGroups/Organizations"
import * as cohorts from "./PageGroups/cohorts"
import * as ths from "./PageGroups/thsadmin"

const routesDef = [
  {
    path: ROUTES.DENIED,
    requireAdmin: false,
    element: <Denied />,
  },
  {
    path: ROUTES.HOMEPAGE,
    requireAdmin: true,
    element: <Home />,
  },
  {
    path: ROUTES.ERROR,
    requireAdmin: true,
    element: <ErrorPage />,
  },
  // Forms
  {
    path: ROUTES.GLOBAL_FORM,
    requireAdmin: true,
    element: <forms.Form />,
  },
  {
    path: ROUTES.GLOBAL_FORM_EDIT,
    requireAdmin: true,
    element: <forms.Form />,
  },
  {
    path: ROUTES.REGISTRATION_FORM,
    requireAdmin: false,
    element: <forms.Form />,
  },
  {
    path: ROUTES.THANK_YOU,
    requireAdmin: false,
    element: <forms.ThankYou />,
  },
  // Resources
  {
    path: ROUTES.EDIT_RESOURCE,
    requireAdmin: true,
    element: <resources.Edit />,
  },
  // Lessons
  {
    path: ROUTES.ADD_LESSON,
    requireAdmin: true,
    element: <lessons.Add />,
  },
  {
    path: ROUTES.EDIT_LESSON,
    requireAdmin: true,
    element: <lessons.Edit />,
  },
  {
    path: ROUTES.LIST_LESSONS,
    requireAdmin: true,
    element: <lessons.List />,
  },
  {
    path: ROUTES.VIEW_LESSON,
    requireAdmin: true,
    element: <lessons.View />,
  },
  {
    path: ROUTES.PREVIEW_LESSON,
    requireAdmin: true,
    element: <lessons.Preview />,
  },
  // Courses
  {
    path: ROUTES.ADD_COURSE,
    requireAdmin: true,
    element: <courses.Add />,
  },
  {
    path: ROUTES.EDIT_COURSE,
    requireAdmin: true,
    element: <courses.Edit />,
  },
  {
    path: ROUTES.LIST_COURSES,
    requireAdmin: true,
    element: <courses.List />,
  },
  {
    path: ROUTES.VIEW_COURSE,
    requireAdmin: true,
    element: <courses.View />,
  },
  {
    path: ROUTES.PREVIEW_COURSE,
    requireAdmin: true,
    element: <courses.Preview />,
  },
  // Organizations

  {
    path: ROUTES.LIST_ORGANIZATION,
    requireAdmin: true,
    element: <organization.List />,
  },
  // Cohorts

  {
    path: ROUTES.LIST_COHORT,
    requireAdmin: true,
    element: <cohorts.List />,
  },
  // Ths Admin
  {
    path: ROUTES.THS_ACCOUNTS,
    requireAdmin: true,
    element: <ths.Accounts />,
  },
  {
    path: ROUTES.THS_ASSESSMENTS,
    requireAdmin: true,
    element: <ths.Assessments />,
  },
  {
    path: ROUTES.THS_COACHING_ASIGNMENTS,
    requireAdmin: true,
    element: <ths.CoachingAssignments />,
  },
  {
    path: ROUTES.THS_LESSON_ENGAGEMENTS,
    requireAdmin: true,
    element: <ths.lessonEngagements />,
  },

  {
    path: ROUTES.THS_RESOURCES,
    requireAdmin: true,
    element: <ths.resources />,
  },
  // Staff
  {
    path: ROUTES.STAFF_LIST,
    requireAdmin: true,
    element: <staff.List />,
  },
  {
    path: ROUTES.STAFF_ASSESSMENTS,
    requireAdmin: true,
    element: <staff.Assessments />,
  },
  {
    path: ROUTES.STAFF_COACHING,
    requireAdmin: true,
    element: <staff.Coaching />,
  },
  {
    path: ROUTES.STAFF_NOTES,
    requireAdmin: true,
    element: <staff.Notes />,
  },
  {
    path: ROUTES.STAFF_PROFILE,
    requireAdmin: true,
    element: <staff.Profile />,
  },

  // Participants

  {
    path: ROUTES.PARTICIPANT_LIST,
    requireAdmin: true,
    element: <participants.List />,
  },

  {
    path: ROUTES.PARTICIPANT_LIST_ALL,
    requireAdmin: true,
    element: <participants.ListAll />,
  },

  {
    path: ROUTES.PARTICIPANT_PROFILE,
    requireAdmin: true,
    element: <participants.Profile />,
  },

  {
    path: ROUTES.PARTICIPANT_ASSESSMENTS,
    requireAdmin: true,
    element: <participants.Assessments />,
  },
  {
    path: ROUTES.PARTICIPANT_COURSES,
    requireAdmin: true,
    element: <participants.Courses />,
  },
  {
    path: ROUTES.PARTICIPANT_PROFILE_VIEW,
    requireAdmin: true,
    element: <participants.ProfileView />,
  },
]

export { routesDef }
