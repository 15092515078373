import React from "react"

export default function Assessments() {
  return (
    <div>
      <h1> Participants </h1>
      <p> Assessments</p>
    </div>
  )
}
