import React, { useEffect } from "react"

import { useFormikContext } from "formik"

// PR
import { Button } from "primereact/button"
import { FileUpload } from "primereact/fileupload"
import { ProgressBar } from "primereact/progressbar"

// Hooks
import useStorageFileUploader from "../../../hooks/useStorageFileUploader"
import propTypes from "prop-types"

export default function HandleTypeFile(props) {
  const formik = useFormikContext()

  const STORAGE_PATH = `resources/${props.type}`
  const FILE_TYPE = props.type
  const FILE_SIZE = 1e9 // 1Gb

  const CURRENT_GS = formik.values.gs || ""

  const [customUploadHandler, removeHandler, clear, progress, gsRef] =
    useStorageFileUploader(STORAGE_PATH, FILE_TYPE, CURRENT_GS)

  useEffect(() => {
    formik.setFieldValue("gs", gsRef)
  }, [gsRef])

  const CURRENT_SRC = formik.values.src || ""
  const isPublicSrc = CURRENT_SRC.includes("https://")

  const handleContentAccept = () => {
    switch (FILE_TYPE) {
      case "image":
        return "image/*"
      case "audio":
        return "audio/*"
      case "video":
        return "video/*"
      case "file":
        return "application/pdf"
      default:
        return ""
    }
  }

  const handleResourceRendering = () => {
    switch (props.type) {
      case "video":
        return (
          <video
            src={CURRENT_SRC}
            controls
            style={{ width: 520, height: "auto" }}
          />
        )
      case "audio":
        return (
          <audio
            src={CURRENT_SRC}
            controls
            style={{
              width: "100%",
              height: "2rem",
              display: "block",
            }}
          />
        )
      case "file":
        return (
          <a href={CURRENT_SRC} target="_blank" rel="noreferrer">
            {CURRENT_SRC}
          </a>
        )
      default:
        return null
    }
  }

  if (isPublicSrc) {
    return (
      <div>
        <div>{handleResourceRendering()}</div>
        <Button
          label="Replace resource"
          className="my-3"
          outlined
          size="small"
          onClick={() => {
            formik.setFieldValue("src", "")
            formik.setFieldValue("gs", "")
          }}
        />
      </div>
    )
  }

  return (
    <div>
      <div>
        <FileUpload
          customUpload
          uploadHandler={customUploadHandler}
          onRemove={removeHandler}
          onClear={clear}
          accept={handleContentAccept()}
          maxFileSize={FILE_SIZE}
        />
        <ProgressBar value={progress} style={{ margin: "10px 0" }} />
      </div>
    </div>
  )
}

HandleTypeFile.propTypes = {
  type: propTypes.string.isRequired,
}
