import React, { useContext, useEffect } from "react"
import { InputTextarea } from "primereact/inputtextarea"
import { Divider } from "primereact/divider"

import { AddLessonCtx } from "../../context/addLessonCtx"

export default function TypeText() {
  const context = useContext(AddLessonCtx)
  const { value } = context.newResource.content
  useEffect(() => {
    context.handleOnChange("htmlTag", "p")
  }, [])
  return (
    <div>
      <small>
        Plain text refers to the simplest form of digital text, devoid of any
        formatting or styling such as bold or italics. It offers simplicity and
        compatibility across various platforms and systems.
      </small>
      <Divider />
      <div className="flex flex-column">
        <label htmlFor="description">Value</label>
        <InputTextarea
          id="value"
          name="value"
          value={value}
          rows={5}
          cols={30}
          onChange={(e) =>
            context.handleOnChange(e.target.name, e.target.value)
          }
        />
      </div>
    </div>
  )
}
