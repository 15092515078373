import React, { useContext } from "react"
import { Field } from "formik"

// Ctx
import { FormContext } from "../context/FormContext"

import { Editor } from "primereact/editor"

import propTypes from "prop-types"
export default function TypeTextareaRich({ field }) {
  const renderHeader = () => {
    return (
      <span className="ql-formats">
        <button className="ql-bold" aria-label="Bold"></button>
        <button className="ql-italic" aria-label="Italic"></button>
        <button className="ql-underline" aria-label="Underline"></button>
        <button
          className="ql-list"
          value="ordered"
          aria-label="Ordered List"
        ></button>
        <button
          className="ql-list"
          value="bullet"
          aria-label="Bullet List"
        ></button>
        <select className="ql-size" aria-label="Font Size">
          <option value="small">Small</option>
          <option value="">Medium</option>
          <option value="large">Large</option>
        </select>
      </span>
    )
  }

  const header = renderHeader()

  const form = useContext(FormContext)
  const blockCurrent = form.blockCurrent

  const isDisabled = form
    ? form.blocksSaved[blockCurrent.block_name] || field.field_disabled_on_edit
    : false
  const isRequired = field.field_validation?.required?.[0] === "true"

  return (
    <Field name={field.field_name}>
      {({ field: formikField, meta, form: formik }) => {
        if (isDisabled) {
          return (
            <div className="flex flex-column gap-1 mb-5">
              <label htmlFor={formikField.name}>
                {field.field_label}
                {isRequired && <span style={{ color: "red" }}>*</span>}
              </label>
              <div
                className="surface-100 text-500 p-2"
                dangerouslySetInnerHTML={{ __html: formikField.value }}
              />
            </div>
          )
        }
        return (
          <div className="flex flex-column gap-1 mb-5">
            <label htmlFor={formikField.name}>
              {field.field_label}
              {isRequired && <span style={{ color: "red" }}>*</span>}
            </label>
            {field.field_description && (
              <small>{field.field_description}</small>
            )}
            <Editor
              id={field.field_name}
              name={formikField.name}
              value={formikField.value}
              onTextChange={(e) =>
                formik.setFieldValue(field.field_name, e.htmlValue)
              }
              headerTemplate={header}
              style={{ height: "320px", minWidth: "480px" }}
            />

            {meta.touched && meta.error && (
              <div className="text-sm text-red-600 font-semibold">
                {meta.error}
              </div>
            )}
          </div>
        )
      }}
    </Field>
  )
}

TypeTextareaRich.propTypes = {
  field: propTypes.object.isRequired,
}
