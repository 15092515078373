import React, { useEffect } from "react"
import { useParams } from "react-router-dom"

import { useFormikContext } from "formik"

// Ctx

import propTypes from "prop-types"

export default function TypeStaffId({ field }) {
  const formik = useFormikContext()
  const { idRef } = useParams()

  console.log("TypeStaffId", field, idRef)

  useEffect(() => {
    // const trigger = field.field_name
    // if (trigger === "staff_id") {
    //   return formik.setFieldValue(field.field_name, user.staff_id)
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div style={{ display: "none" }}>
      <input
        type="hidden"
        name={field.field_name}
        value={formik.values[field.field_name]}
      />
    </div>
  )
}

TypeStaffId.propTypes = {
  field: propTypes.object,
}
