import React, { useRef, useContext } from "react"
import { useQuery, useMutation, gql } from "@apollo/client"
import { LoaderMedium } from "../../components/Loaders"
import { UserContext } from "../../context/userContext"
//RP
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Button } from "primereact/button"
import { useNavigate } from "react-router-dom"
import { SplitButton } from "primereact/splitbutton"
import { DateTime } from "luxon"
import { Toast } from "primereact/toast"
//Ga
import gATracking from "../../helpers/gaTracking"
import getActualTime from "../../helpers/getActualTime"

const GET_COHORTS = gql`
  query organizationCohorts {
    organizationCohorts {
      organization_cohort_id
      organization_name
      organization_status
      organization_cohort_name
      course_name
      cohort_number_seats
      cohort_number_seats_available
      cohort_number_sessions
      cohort_date_start
      cohort_date_end
      cohort_registration_link
      date_created
    }
  }
`
const DELETE_COHORTS = gql`
  mutation deleteOrganizationCohort($organization_cohort_id: ID!) {
    deleteOrganizationCohort(organization_cohort_id: $organization_cohort_id) {
      message
    }
  }
`
export default function OrganizationList() {
  const navigate = useNavigate()
  const toast = useRef(null)
  const userCtx = useContext(UserContext)
  const date = getActualTime()
  const { loading, error, data } = useQuery(GET_COHORTS, {
    fetchPolicy: "network-only",
  })

  const [deleteCohort] = useMutation(DELETE_COHORTS, {
    onCompleted: (data) => {
      if (data?.deleteOrganizationCohort?.message) {
        if (toast.current) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: data.deleteOrganizationCohort.message,
            life: 3000,
          })
        }
      }
    },
    onError: (error) => {
      if (toast.current) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error.message,
          life: 3000,
        })
      }
    },
    refetchQueries: [{ query: GET_COHORTS }],
  })

  if (loading) return <LoaderMedium />
  if (error) return <div>Error! {error.message}</div>

  const cohorts = data.organizationCohorts

  /*Google Analytics tracking*/
  const gaTrackingParams = {
    event: "",
    userCtx,
    payload: {},
  }
  /*End Google Analytics tracking*/

  // Copy Registration Link
  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert("Registration link copied to clipboard!")
      })
      .catch((err) => {
        console.error("Failed to copy: ", err)
      })
  }
  // Actions Edit, Delete and Copy Registration Link
  const action = ({ organization_cohort_id, cohort_registration_link }) => {
    const items = [
      {
        label: "Registration Link",
        icon: "pi pi-copy",
        command: () => copyToClipboard(cohort_registration_link),
      },
      {
        label: "Delete",
        icon: "pi pi-trash",
        command: () =>
          deleteCohort({
            variables: { organization_cohort_id },
            refetchQueries: [{ query: GET_COHORTS }],
          }),
      },
    ]
    return (
      <SplitButton
        label="Edit"
        model={items}
        onClick={() => {
          gaTrackingParams.event = "edit"
          gaTrackingParams.payload = {
            content_id: organization_cohort_id,
            content_type: "Cohort",
            timestamp: date,
          }
          gATracking(gaTrackingParams)

          navigate(`/form/edit/organization_cohort/1/${organization_cohort_id}`)
        }}
      />
    )
  }

  // Function to format dates using Luxon
  const formatDate = (dateString) => {
    return DateTime.fromISO(dateString).toFormat("MM/dd/yyyy")
  }

  return (
    <div className="p-col-12 p-md-3">
      <div className="flex gap-5 align-items-center mb-4">
        <h2>Organization Cohort List</h2>
        <Toast ref={toast} />

        <Button
          icon="pi pi-plus"
          rounded
          onClick={() => navigate("/form/organization_cohort/1")}
          severity="success"
          style={{
            backgroundColor: "var(--branded-dark-blue)",
            borderColor: "var(--branded-dark-blue)",
          }}
          aria-label="Add"
        />
      </div>

      <DataTable
        value={cohorts}
        paginator
        rows={10}
        resizableColumns
        showGridlines
        scrollHeight="flex"
        className="w-full	"
        style={{ tableLayout: "fixed", maxWidth: "100%" }}
      >
        <Column
          sortable
          filter
          field="organization_name"
          header="Organization"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "175px", wordWrap: "break-word" }}
        ></Column>
        <Column
          sortable
          filter
          field="organization_cohort_name"
          header="Cohort "
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          filter
          field="course_name"
          header="Course"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          field="cohort_number_seats"
          header="Total Seats"
          headerStyle={{ whiteSpace: "pre-line" }}
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          field="cohort_number_seats_available"
          header="Available Seats"
          headerStyle={{ whiteSpace: "pre-line" }}
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          field="cohort_number_sessions"
          header="Sessions"
          headerStyle={{ whiteSpace: "pre-line" }}
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "150px", wordWrap: "break-word" }}
        />

        <Column
          field="cohort_date_start"
          header="Start Date"
          headerStyle={{ whiteSpace: "pre-line" }}
          body={(rowData) => formatDate(rowData.cohort_date_start)}
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
        <Column
          field="cohort_date_end"
          header="End Date"
          headerStyle={{ whiteSpace: "pre-line" }}
          body={(rowData) => formatDate(rowData.cohort_date_end)}
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "90px", wordWrap: "break-word" }}
        />
        <Column
          field="organization_status"
          header="Status"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
        <Column
          body={(row) => action(row)}
          header="Action"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
      </DataTable>
    </div>
  )
}
