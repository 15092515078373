import React, { useContext, useState } from "react"
import { useQuery, gql } from "@apollo/client"

import { DateTime } from "luxon"

import { LoaderMedium } from "../../components/Loaders"
import { UserContext } from "../../context/userContext"

import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Dropdown } from "primereact/dropdown"

const ASSESSMENT_LIST = gql`
  query assessmentsByStaffId($staff_id: ID!) {
    assessments: assessmentsByStaffId(staff_id: $staff_id) {
      assessment_name
      organization_cohort_name
      participant_name_full
      assessment_date
      total_score_personal_performance
      total_score_satisfaction_life
      total_score_resilience_stress
      total_score_assessment
      date_created
    }
  }
`

export default function Assessments() {
  const [selectedCohort, setSelectedCohort] = useState(null)

  const userCtx = useContext(UserContext)
  const staffID = userCtx.session_id

  const { loading, error, data } = useQuery(ASSESSMENT_LIST, {
    variables: { staff_id: staffID },
    fetchPolicy: "network-only",
  })

  if (loading) return <LoaderMedium />
  if (error) return <div>Error! {error.message}</div>

  const assessments = data.assessments

  const dateTemplate = (rowData) => {
    const parsedDate = DateTime.fromFormat(
      rowData.date_created,
      "yyyy-MM-dd HH:mm:ss"
    )
    return parsedDate.toFormat("MM/dd/yyyy")
  }
  const filteredAssessments =
    selectedCohort && selectedCohort.name !== "All"
      ? assessments.filter(
          (assessment) =>
            assessment.organization_cohort_name === selectedCohort.name
        )
      : assessments

  const uniqueCohorts = [
    "All",
    ...new Set(
      data.assessments && data.assessments.length > 0
        ? data.assessments.map(
            (assessment) => assessment.organization_cohort_name
          )
        : []
    ),
  ]

  return (
    <div className="p-col-12 p-md-3">
      <h1>Assessments</h1>
      {uniqueCohorts.length > 0 ? (
        <Dropdown
          value={selectedCohort}
          onChange={(e) => setSelectedCohort(e.value)}
          options={uniqueCohorts.map((cohort) => ({ name: cohort }))}
          optionLabel="name"
          placeholder="Select a Cohort"
          className="w-full md:w-14rem"
        />
      ) : (
        <p>No cohorts available</p>
      )}

      <br />
      <br />

      <DataTable
        value={filteredAssessments}
        paginator
        rows={10}
        resizableColumns
        showGridlines
        scrollHeight="flex"
        className="w-full	"
        style={{ tableLayout: "fixed", maxWidth: "100%" }}
      >
        <Column
          sortable
          filter
          field="participant_name_full"
          header="Participant"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          filter
          field="organization_cohort_name"
          header="Cohort"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "250px", wordWrap: "break-word" }}
        />

        <Column
          sortable
          filter
          field="assessment_date"
          header="Date Submitted"
          body={dateTemplate}
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
        <Column
          header="Overall Life Satisfaction"
          field="total_score_satisfaction_life"
          headerStyle={{ whiteSpace: "pre-line" }}
          sortable
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "150px", wordWrap: "break-word" }}
        />
        <Column
          field="total_score_resilience_stress"
          header="Overall Resilience to Stress"
          headerStyle={{ whiteSpace: "pre-line" }}
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "150px", wordWrap: "break-word" }}
          sortable
        />
        <Column
          field="total_score_personal_performance"
          header="Overall Personal Performance"
          headerStyle={{ whiteSpace: "pre-line" }}
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "150px", wordWrap: "break-word" }}
          sortable
        />
        <Column
          field="total_score_assessment"
          header="Overall Well Being"
          headerStyle={{ whiteSpace: "pre-line" }}
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "150px", wordWrap: "break-word" }}
          sortable
        />
      </DataTable>
    </div>
  )
}
