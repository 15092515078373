import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { useQuery, gql } from "@apollo/client"
import { auth } from "../../firebase"
import gaTracking from "../../helpers/gaTracking"

import { UserContext } from "../../context/userContext"
import ROUTES from "../../components/Routes/routesConstants"

const GET_PARTICIPANT_MENU = gql`
  query ParticipantMenu($participant_id: ID!) {
    current: participant(participant_id: $participant_id) {
      course_id
      course_name
    }
    cohort: organizationCohortsByParticipant(participant_id: $participant_id) {
      course_id
      course_name
    }
  }
`

const useUserRoleMenu = (setAssessment) => {
  const navigate = useNavigate()
  const userCtx = useContext(UserContext)

  const { loading, error, data } = useQuery(GET_PARTICIPANT_MENU, {
    variables: { participant_id: userCtx.session_id },
    fetchPolicy: "network-only",
    skip: userCtx.role_id !== "auth-user",
  })

  if (loading) return null

  if (error) {
    console.error("Error fetching participant menu:", error)
    return null
  }

  /*Google Analytics tracking*/
  const gaTrackingParams = {
    event: "button_click",
    userCtx,
    payload: {},
  }
  /*End Google Analytics tracking*/

  const handleCurrentCourse = () => {
    if (!data?.current?.course_id || error) {
      return { label: "No Current Course", icon: "pi pi-ban" }
    }
    const coursePath = `/view/course/${data?.current.course_id}`
    return {
      label: data?.current.course_name,
      icon: "pi pi-star",
      command: () => {
        gaTrackingParams.payload = {
          button_id: "CurrentCourseLink",
          menu_link: "Current Course",
          button_text: data?.current.course_name,
          button_type: "menu_link",
          button_location: "participant_header",
        }
        gaTracking(gaTrackingParams)

        navigate(coursePath)
      },
    }
  }

  const shouldDisplayArchived =
    data?.cohort?.length > 1 &&
    data?.cohort.some((cohort) => cohort.course_id !== data.current?.course_id)

  const staffUserMenu = [
    {
      label: "My Participants",
      icon: "pi pi-users",
      command: () => {
        gaTrackingParams.payload = {
          button_name: "My Participants",
          button_text: "My Participants",
          button_type: "menu_link",
          button_location: "staff_header",
        }
        gaTracking(gaTrackingParams)

        navigate(ROUTES.HOMEPAGE)
      },
    },
    {
      label: "Assessments",
      icon: "pi pi-list",
      command: () => {
        gaTrackingParams.payload = {
          button_name: "Assessments",
          button_text: "Assessments",
          button_type: "menu_link",
          button_location: "staff_header",
        }
        gaTracking(gaTrackingParams)

        navigate(ROUTES.STAFF_ASSESSMENTS)
      },
    },
    {
      label: "Lesson Reflections",
      icon: "pi pi-list",
      command: () => {
        gaTrackingParams.payload = {
          button_name: "Lesson Reflections",
          button_text: "Lesson Reflections",
          button_type: "menu_link",
          button_location: "staff_header",
        }
        gaTracking(gaTrackingParams)

        navigate(ROUTES.STAFF_COACHING)
      },
    },
    {
      label: "Coaching Notes",
      icon: "pi pi-book",
      command: () => {
        gaTrackingParams.payload = {
          button_name: "Coaching Notes",
          button_text: "Coaching Notes",
          button_type: "menu_link",
          button_location: "staff_header",
        }
        gaTracking(gaTrackingParams)

        navigate(ROUTES.STAFF_NOTES)
      },
    },
    {
      label: "Sign Out",
      icon: "pi pi-sign-out",
      command: () => {
        gaTrackingParams.event = "logout"
        gaTrackingParams.payload = {
          logout_method: "Log_out_button",
        }
        gaTracking(gaTrackingParams)

        navigate("/", { replace: true })
        auth.signOut()
      },
    },
  ]

  const sysAdminMenu = [
    {
      label: "Home",
      icon: "pi pi-home",
      command: () => navigate(ROUTES.HOMEPAGE),
      items: [
        {
          label: "Profile",
          icon: "pi pi-user",
          command: () => navigate(ROUTES.HOMEPAGE),
        },
        {
          label: "Sign Out",
          icon: "pi pi-sign-out",
          command: () => {
            gaTrackingParams.event = "logout"
            gaTrackingParams.payload = {
              logout_method: "Log_out_button",
            }
            gaTracking(gaTrackingParams)
            auth.signOut()
            navigate(ROUTES.HOMEPAGE)
          },
        },
      ],
    },
    {
      label: "Courses",
      icon: "pi pi-bookmark",
      items: [
        {
          label: "Add Course",
          icon: "pi pi-plus",
          command: () => navigate(ROUTES.ADD_COURSE),
        },
        {
          label: "List Courses",
          icon: "pi pi-list",
          command: () => navigate(ROUTES.LIST_COURSES),
        },
      ],
    },
    {
      label: "Lessons",
      icon: "pi pi-book",
      items: [
        {
          label: "Add Lesson",
          icon: "pi pi-plus",
          command: () => navigate(ROUTES.ADD_LESSON),
        },
        {
          label: "List Lessons",
          icon: "pi pi-list",
          command: () => navigate(ROUTES.LIST_LESSONS),
        },
        {
          label: "Resources",
          icon: "pi pi-palette",
          command: () => navigate(ROUTES.HOMEPAGE),
        },
      ],
    },
    {
      label: "Orgnizations",
      icon: "pi pi-globe",
      items: [
        {
          label: "Add Organization",
          icon: "pi pi-plus",
          command: () => navigate(ROUTES.ADD_ORGANIZATION),
        },
        {
          label: "Organization List",
          icon: "pi pi-list",
          command: () => navigate(ROUTES.LIST_ORGANIZATION),
        },
        {
          label: "Add Organization Cohort",
          icon: "pi pi-plus",
          command: () => navigate(ROUTES.ADD_COHORT),
        },
        {
          label: "Organization Cohort List",
          icon: "pi pi-list",
          command: () => navigate(ROUTES.LIST_COHORT),
        },
      ],
    },
    {
      label: "Coaches",
      icon: "pi pi-id-card",
      items: [
        {
          label: "Add Coach",
          icon: "pi pi-user-plus",
          command: () => navigate(ROUTES.HOMEPAGE),
        },
        {
          label: "List Coaches",
          icon: "pi pi-list",
          command: () => navigate(ROUTES.HOMEPAGE),
        },
      ],
    },
    {
      label: "Participants",
      icon: "pi pi-users",
      items: [
        {
          label: "List Participants",
          icon: "pi pi-list",
          command: () => navigate(ROUTES.PARTICIPANT_LIST_ALL),
        },
      ],
    },
  ]

  const thsAdminMenu = [
    {
      label: "Participants",
      icon: "pi pi-users",

      items: [
        {
          label: "All Participants",
          icon: "pi pi-list",
          command: () => {
            gaTrackingParams.payload = {
              button_name: "all_participants_menu_link",
              button_text: "All Participants",
              button_type: "menu_link",
              button_location: "thsAdmin_header",
            }
            gaTracking(gaTrackingParams)

            navigate(ROUTES.PARTICIPANT_LIST_ALL)
          },
        },
        {
          label: "New Registrations",
          icon: "pi pi-list",
          command: () => {
            gaTrackingParams.payload = {
              button_name: "new_registrations_menu_link",
              button_text: "New Registrations",
              button_type: "menu_link",
              button_location: "thsAdmin_header",
            }
            gaTracking(gaTrackingParams)
            navigate(ROUTES.THS_COACHING_ASIGNMENTS)
          },
        },
        {
          label: "Coaching Assignments",
          icon: "pi pi-list",
          command: () => {
            gaTrackingParams.payload = {
              button_name: "coaching_assignments_menu_link",
              button_text: "Coaching Assigments",
              button_type: "menu_link",
              button_location: "thsAdmin_header",
            }
            gaTracking(gaTrackingParams)
            navigate(ROUTES.PARTICIPANT_LIST)
          },
        },
        {
          label: "Lesson Engagements",
          icon: "pi pi-list",
          command: () => {
            gaTrackingParams.payload = {
              button_name: "lesson_engagements_menu_link",
              button_text: "Lesson Engagements",
              button_type: "menu_link",
              button_location: "thsAdmin_header",
            }
            gaTracking(gaTrackingParams)

            navigate(ROUTES.THS_LESSON_ENGAGEMENTS)
          },
        },
      ],
    },
    {
      label: "Assessments",
      icon: "pi pi-verified",
      command: () => {
        gaTrackingParams.payload = {
          button_name: "assessments_menu_link",
          button_text: "Assessments",
          button_type: "menu_link",
          button_location: "thsAdmin_header",
        }
        gaTracking(gaTrackingParams)

        navigate(ROUTES.THS_ASSESSMENTS)
      },
    },
    {
      label: "Courses",
      icon: "pi pi-bookmark",
      items: [
        {
          label: "Add Course",
          icon: "pi pi-plus",
          command: () => {
            gaTrackingParams.payload = {
              button_name: "add_course_menu_link",
              button_text: "Add Course",
              button_type: "menu_link",
              button_location: "thsAdmin_header",
            }
            gaTracking(gaTrackingParams)

            navigate(ROUTES.ADD_COURSE)
          },
        },
        {
          label: "List Courses",
          icon: "pi pi-list",
          command: () => {
            gaTrackingParams.payload = {
              button_name: "list_courses_menu_link",
              button_text: "List Courses",
              button_type: "menu_link",
              button_location: "thsAdmin_header",
            }
            gaTracking(gaTrackingParams)

            navigate(ROUTES.LIST_COURSES)
          },
        },
        {
          label: "Lessons",
          icon: "pi pi-book",
          items: [
            {
              label: "Add Lesson",
              icon: "pi pi-plus",
              command: () => {
                gaTrackingParams.payload = {
                  button_name: "add_lesson_menu_link",
                  button_text: "Add Lesson",
                  button_type: "menu_link",
                  button_location: "thsAdmin_header",
                }
                gaTracking(gaTrackingParams)

                navigate(ROUTES.ADD_LESSON)
              },
            },
            {
              label: "List Lessons",
              icon: "pi pi-list",
              command: () => {
                gaTrackingParams.payload = {
                  button_name: "list_lessons_menu_link",
                  button_text: "List Lessons",
                  button_type: "menu_link",
                  button_location: "thsAdmin_header",
                }
                gaTracking(gaTrackingParams)

                navigate(ROUTES.LIST_LESSONS)
              },
            },
            {
              label: "Resources",
              icon: "pi pi-palette",
              command: () => {
                gaTrackingParams.payload = {
                  button_name: "resources_menu_link",
                  button_text: "Resources",
                  button_type: "menu_link",
                  button_location: "thsAdmin_header",
                }
                gaTracking(gaTrackingParams)

                navigate(ROUTES.THS_RESOURCES)
              },
            },
          ],
        },
      ],
    },
    {
      label: "Organizations",
      icon: "pi pi-globe",
      items: [
        {
          label: "Add Organization",
          icon: "pi pi-plus",
          command: () => {
            gaTrackingParams.payload = {
              button_name: "add_organization_menu_link",
              button_text: "Add Organization",
              button_type: "menu_link",
              button_location: "thsAdmin_header",
            }
            gaTracking(gaTrackingParams)

            navigate(ROUTES.ADD_ORGANIZATION)
          },
        },
        {
          label: "Organization List",
          icon: "pi pi-list",
          command: () => {
            gaTrackingParams.payload = {
              button_name: "organization_list_menu_link",
              button_text: "Organization List",
              button_type: "menu_link",
              button_location: "thsAdmin_header",
            }
            gaTracking(gaTrackingParams)

            navigate(ROUTES.LIST_ORGANIZATION)
          },
        },
        {
          label: "Add Organization Cohort",
          icon: "pi pi-plus",
          command: () => {
            gaTrackingParams.payload = {
              button_name: "add_organization_cohort_menu_link",
              button_text: "Add Organization Cohort",
              button_type: "menu_link",
              button_location: "thsAdmin_header",
            }
            gaTracking(gaTrackingParams)

            navigate(ROUTES.ADD_COHORT)
          },
        },
        {
          label: "Organization Cohort List",
          icon: "pi pi-list",
          command: () => {
            gaTrackingParams.payload = {
              button_name: "organization_cohort_list_menu_link",
              button_text: "Organization Cohort List",
              button_type: "menu_link",
              button_location: "thsAdmin_header",
            }
            gaTracking(gaTrackingParams)

            navigate(ROUTES.LIST_COHORT)
          },
        },
      ],
    },
    {
      label: "Coaches",
      icon: "pi pi-id-card",
      items: [
        {
          label: "Add Coach",
          icon: "pi pi-user-plus",

          command: () => {
            gaTrackingParams.payload = {
              button_name: "add_coach_menu_link",
              button_text: "Add Coach",
              button_type: "menu_link",
              button_location: "thsAdmin_header",
            }
            gaTracking(gaTrackingParams)

            navigate(ROUTES.STAFF_FORM)
          },
        },
        {
          label: "List Coaches",
          icon: "pi pi-list",

          command: () => {
            gaTrackingParams.payload = {
              button_name: "list_coaches_menu_link",
              button_text: "List Coaches",
              button_type: "menu_link",
              button_location: "thsAdmin_header",
            }
            gaTracking(gaTrackingParams)

            navigate(ROUTES.STAFF_LIST)
          },
        },
      ],
    },
    {
      label: "Sign Out",
      icon: "pi pi-sign-out",
      command: () => {
        gaTrackingParams.event = "logout"
        gaTrackingParams.payload = {
          logout_method: "Log_out_button",
        }
        gaTracking(gaTrackingParams)
        navigate("/", { replace: true })
        auth.signOut()
      },
    },
  ]

  const authUserMenu = [
    {
      label: "Home",
      icon: "pi pi-home",
      command: () => navigate(ROUTES.HOMEPAGE),
    },
    {
      label: " Take Assessment",
      icon: "pi pi-verified",
      command: () => {
        gaTrackingParams.payload = {
          button_id: "TakeAssessmentLink",
          menu_link: "Take Assessment",
          button_text: "Take Assessment",
          button_type: "menu_link",
          button_location: "participant_header",
        }
        gaTracking(gaTrackingParams)

        setAssessment(true)
      },
    },
    {
      label: "Courses",
      icon: "pi pi-bookmark",
      items: [
        handleCurrentCourse(),
        shouldDisplayArchived && {
          label: "Archived",
          icon: "pi pi-briefcase",
          command: () => {
            gaTrackingParams.payload = {
              button_id: "CourseLink",
              menu_link: "View Courses",
              button_text: "Courses",
              button_type: "menu_link",
              button_location: "participant_header",
            }
            gaTracking(gaTrackingParams)

            navigate(ROUTES.PARTICIPANT_COURSES)
          },
        },
      ].filter(Boolean), // Filter out falsy values
    },
    {
      label: "Sign Out",
      icon: "pi pi-sign-out",
      command: () => {
        gaTrackingParams.event = "logout"
        gaTrackingParams.payload = {
          logout_method: "Log_out_button",
        }
        gaTracking(gaTrackingParams)
        navigate("/", { replace: true })
        auth.signOut()
      },
    },
  ]

  const getMenuByRole = () => {
    switch (userCtx?.role_id) {
      case "staff-user":
        return staffUserMenu
      case "sys-admin":
        return sysAdminMenu
      case "ths-admin":
        return thsAdminMenu
      case "auth-user":
        return authUserMenu
      default:
        return []
    }
  }

  return getMenuByRole()
}

export default useUserRoleMenu
