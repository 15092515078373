import React from "react"
import { useNavigate, useParams } from "react-router-dom"
import { gql, useQuery } from "@apollo/client"

import { PanelMenu } from "primereact/panelmenu"

import { BreadCrumb } from "primereact/breadcrumb"

import { LoaderMedium } from "../../components/Loaders"

import RenderResourceList from "./components/RenderResourceList"

const GET_LESSON = gql`
  query GetLesson($idCourse: ID!, $idLesson: ID!) {
    course(id: $idCourse) {
      title
      lessons {
        id
        title
      }
    }
    lesson(id: $idLesson) {
      title
      description
      resources {
        avgTime
        content {
          description
          htmlTag
          src
          title
          value
          thumbnail
        }
        description
        id
        type
      }
    }
  }
`

export default function PreviewLesson() {
  const { idCourse, idLesson } = useParams()
  const { loading, error, data } = useQuery(GET_LESSON, {
    variables: { idCourse, idLesson },
    fetchPolicy: "network-only",
  })

  const navigate = useNavigate()

  if (loading) return <LoaderMedium />
  if (error) return <div>Error! {error.message}</div>

  const breadcrumb = [
    {
      label: data.course.title,
      command: () => navigate(`/preview/course/${idCourse}`),
    },
    { label: data.lesson.title },
  ]
  const home = {
    icon: "pi pi-home",
    command: () => navigate("/"),
  }
  const items = [
    {
      key: "0",
      label: data.course.title,
      icon: "pi pi-book",
      items: data.course.lessons.map((lesson) => ({
        key: lesson.id,
        label: lesson.title,
        active: lesson.id === idLesson,
        command: () =>
          navigate(`/preview/course/${idCourse}/lesson/${lesson.id}`),
      })),
    },
  ]

  return (
    <div>
      <h1>{data.lesson.title}</h1>
      <p>{data.lesson.description}</p>

      <BreadCrumb model={breadcrumb} home={home} />
      <div className="flex align-items-start overflow-hidden gap-3 mt-5">
        <div className="flex-none flex w-3 align-items-center justify-content-center bg-bluegray-100 p-2">
          <PanelMenu model={items} style={{ width: "100%" }} />
        </div>
        <div className="flex-grow-1 flex flex-column bg-bluegray-50 pb-8">
          <RenderResourceList resourceList={data.lesson.resources} />
        </div>
      </div>

      <br />
      <br />
    </div>
  )
}
