import React, { useContext } from "react"
import { useFormikContext, Field } from "formik"
import { Checkbox } from "primereact/checkbox"

// Ctx
import { FormContext } from "../context/FormContext"

import _ from "lodash"

import propTypes from "prop-types"

export default function TypeCheckbox({ field }) {
  const formik = useFormikContext()

  const form = useContext(FormContext)
  const blockCurrent = form.blockCurrent

  const isDisabled = form
    ? form.blocksSaved[blockCurrent.block_name] || field.field_disabled_on_edit
    : false
  const isRequired = field.field_validation?.required?.[0] === "true"

  return (
    <div className="flex flex-column gap-1 mb-5">
      <label>
        {field.field_label}
        {isRequired && <span style={{ color: "red" }}>*</span>}
      </label>

      {field.field_description && <div>{field.field_description}</div>}

      {_.map(field.field_options, (option) => {
        return (
          <button
            key={option.value}
            className="field-radiobutton"
            style={{ marginBottom: 5 }}
            onBlur={() => formik.setFieldTouched(field.field_name)}
          >
            <Field name={field.field_name}>
              {({ field: formikField }) => {
                return (
                  <>
                    <Checkbox
                      disabled={isDisabled}
                      inputId={`${field.field_name}_${option.value}`}
                      name={formikField.name}
                      id={field.field_name}
                      value={option.value}
                      onChange={formikField.onChange}
                      checked={_.includes(formikField.value, option.value)}
                    />
                    <label
                      htmlFor={`${field.field_name}_${option.value}`}
                      style={{ marginLeft: 5 }}
                    >
                      {option.label}
                    </label>
                  </>
                )
              }}
            </Field>
          </button>
        )
      })}
    </div>
  )
}

TypeCheckbox.propTypes = {
  field: propTypes.object,
}
