import React, { useEffect } from "react"
import { useParams } from "react-router-dom"

import { useFormikContext } from "formik"

import propTypes from "prop-types"

export default function TypeHidden({ field }) {
  const { idRef } = useParams()
  const formik = useFormikContext()

  useEffect(() => {
    formik.setFieldValue(field.field_name, idRef)
  }, [])

  return (
    <div style={{ display: "none" }}>
      <input type="hidden" name={field.field_name} />
    </div>
  )
}

TypeHidden.propTypes = {
  field: propTypes.object.isRequired,
}
