import React, { useContext, useEffect } from "react"
import { Editor } from "primereact/editor"

import { AddLessonCtx } from "../../context/addLessonCtx"

export default function TypeEditor() {
  const context = useContext(AddLessonCtx)
  const { value } = context.newResource.content

  useEffect(() => {
    context.handleOnChange("htmlTag", "InnerHTML")
  }, [])

  const renderHeader = () => {
    return (
      <span className="ql-formats">
        <button className="ql-bold" aria-label="Bold"></button>
        <button className="ql-italic" aria-label="Italic"></button>
        <button className="ql-underline" aria-label="Underline"></button>
        <button
          className="ql-list"
          value="ordered"
          aria-label="Ordered List"
        ></button>
        <button
          className="ql-list"
          value="bullet"
          aria-label="Bullet List"
        ></button>
      </span>
    )
  }

  const header = renderHeader()

  return (
    <div className="card">
      <Editor
        name="value"
        id="value"
        value={value}
        onTextChange={(e) => context.handleOnChange("value", e.htmlValue)}
        headerTemplate={header}
        style={{ height: "320px" }}
      />
    </div>
  )
}
