import React, { useState, useContext } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { UserContext } from "../../context/userContext"
import { LoaderMedium } from "../../components/Loaders"
import ParticipantAssessmentScore from "../Participants/ParticipantAssessmentScore"

import { DateTime } from "luxon"

// Prime React

import { Card } from "primereact/card"
import { useQuery, gql } from "@apollo/client"
import { TabMenu } from "primereact/tabmenu"
import { Button } from "primereact/button"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"

// Queries de GraphQL
const GET_PARTICIPANTS = gql`
  query Participant($participant_id: ID!) {
    participant(participant_id: $participant_id) {
      participant_id
      participant_coaching_registration_id
      participant_coaching_id
      participant_name_first
      participant_name_last
      participant_name_full
      participant_email
      participant_status
      demo_dob
      consent_data_store
      consent_data_share
      participant_timezone
      participant_sms
      participant_sms_phone
      participant_work_title
      participant_work_tenure
      terms_of_service_privacy
      participant_improvement_focus_primary
      participant_improvement_focus_secondary
      participant_readiness_change_habits
      participant_readiness_change_support
      participant_motivation
      demo_permission
      demo_gender
      demo_education_level
      demo_relationship_status
      demo_race
      demo_ethnicity
      participant_profile_image_link_400x400
      participant_comments
      organization_cohort_id
      organization_cohort_name
      course_id
      course_name
      staff_id
      staff_name_full
      organization_id
      organization_name
      date_created
      date_modified
      participant_status
      participant_lesson_log {
        participant_lesson_record_id
        participant_coaching_registration_id
        course_id
        course_name
        course_lesson_id
        course_lesson_name
        course_lesson_complete
        course_lesson_reflection
        organization_id
        organization_cohort_id
        staff_id
        date_created
        date_modified
      }
      participant_assessments {
        assessment_current {
          assessment_type
          assessment_date
          total_score_personal_performance
          total_score_satisfaction_life
          total_score_resilience_stress
          total_score_assessment
        }
        assessment_diff {
          assessment_type
          assessment_date
          diff_score_personal_performance
          diff_score_satisfaction_life
          diff_score_resilience_stress
          diff_score_assessment
        }
        assessment_log {
          assessment_id
          assessment_name
          organization_id
          organization_cohort_id
          participant_id
          participant_coaching_registration_id
          assessment_date
          total_score_personal_performance
          total_score_satisfaction_life
          total_score_resilience_stress
          total_score_assessment
          date_created
          date_modified
        }
      }
    }
  }
`

export default function ParticipantHome() {
  const userCtx = useContext(UserContext)
  const { idParticipant } = useParams()
  const navigate = useNavigate()

  const [activeTab, setActiveTab] = useState("PersonalData")

  const { loading, error, data } = useQuery(GET_PARTICIPANTS, {
    variables: { participant_id: idParticipant },
    fetchPolicy: "network-only",
    skip: !idParticipant,
  })

  const dateTemplate = (dateObject) => {
    const isoDateTime = DateTime.fromISO(
      dateObject.assessment_date.replace(" ", "T")
    )
    return isoDateTime.toFormat("MM/dd/yyyy")
  }

  if (loading) return <LoaderMedium />
  if (error) return <div>Error! {error.message}</div>
  if (loading) return <LoaderMedium />

  const participant = data.participant

  const items = [
    {
      label: "Personal Data",
      icon: "pi pi-home",
      command: () => setActiveTab("PersonalData"),
    },
    {
      label: "Assessments",
      icon: "pi pi-chart-line",
      command: () => setActiveTab("Assessments"),
    },
  ]

  const parseText = (string) => {
    const tempString = document.createElement("div")
    tempString.innerHTML = string
    return tempString.textContent || tempString.innerText || ""
  }

  return (
    <section>
      <TabMenu model={items} activeItem={activeTab} />

      {activeTab === "PersonalData" && (
        <div>
          <div className="flex flex-column mt-5 gap-5">
            <div className="flex gap-5">
              <div className="flex flex-column gap-5">
                <Card
                  className="w-30rem shadow-3"
                  style={{
                    height: "52rem",
                    border: "solid 1px #3175bb",
                  }}
                >
                  <div className="flex justify-content-center">
                    <img
                      alt="Participant Profile"
                      className="w-20rem h-20rem my-3"
                      style={{ borderRadius: "50%", objectFit: "cover" }}
                      src={participant.participant_profile_image_link_400x400}
                    />
                  </div>
                  <h2>Personal Profile</h2>
                  <hr></hr>
                  <div className="flex flex-column justify-content-around">
                    <p>
                      <b>Name: </b>
                      {participant.participant_name_full}
                    </p>
                    <p>
                      <b>Email: </b>
                      {participant.participant_email}
                    </p>
                    <p>
                      <b>Phone Number: </b>
                      {participant.participant_sms_phone}
                    </p>

                    <p>
                      <b>Birth Date: </b>
                      {DateTime.fromISO(participant.demo_dob).toFormat(
                        "MM/dd/yyyy"
                      )}
                    </p>

                    <p>
                      <b>Time Zone: </b>
                      {participant.participant_timezone}
                    </p>
                    {userCtx.role_id === "ths-admin" && (
                      <Button
                        className="w-3 mt-2"
                        label="Edit"
                        onClick={() =>
                          navigate(`/form/edit/profile/1/${idParticipant}`)
                        }
                      />
                    )}
                  </div>
                </Card>
                <Card
                  className="w-30rem shadow-3"
                  style={{
                    height: "25rem",
                    border: "solid 1px #3175bb",
                  }}
                >
                  <h2>Demographic Information</h2>
                  <hr />
                  <div className="flex flex-column justify-content-around">
                    <p>
                      <b>Gender: </b>
                      {participant.demo_gender}
                    </p>
                    <p>
                      <b>Education: </b>
                      {participant.demo_education_level}
                    </p>
                    <p>
                      <b>Relationship: </b>
                      {participant.demo_relationship_status}
                    </p>
                    <p>
                      <b>Race: </b>
                      {participant.demo_race.join(", ")}{" "}
                    </p>
                    <p>
                      <b>Ethnicity: </b>
                      {participant.demo_ethnicity.join(", ")}{" "}
                    </p>
                  </div>
                </Card>
              </div>
              <Card
                className="shadow-3"
                style={{
                  width: "50rem",
                  border: "solid 1px #21becb",
                }}
              >
                <h2>Motivation</h2>
                <hr></hr>
                <div className="flex flex-column justify-content-around">
                  <p>
                    <b>Primary Focus: </b>{" "}
                    {participant.participant_improvement_focus_primary}
                  </p>
                  <p>
                    <b>Secondary Focus: </b>{" "}
                    {participant.participant_improvement_focus_secondary}
                  </p>
                  <p>
                    <b>
                      The present participant is ready to change their habits?:
                    </b>{" "}
                    {participant.participant_readiness_change_habits}
                  </p>

                  <p>
                    <b>The present participant is ready to receive support?:</b>{" "}
                    {participant.participant_readiness_change_support}
                  </p>

                  <p>
                    <b>
                      Personal motivation and what the participant is hoping to
                      learn:
                    </b>
                    {" " + parseText(participant.participant_motivation)}
                  </p>
                </div>
                <h2 className="mt-4">Professional Information</h2>
                <hr></hr>
                <div className="flex flex-column justify-content-around">
                  <p>
                    <b>Status: </b>
                    {participant.participant_status}
                  </p>
                  <p>
                    <b>Organization: </b>
                    {participant.organization_name}
                  </p>
                  <p>
                    <b>Cohort: </b>
                    {participant.organization_cohort_name}
                  </p>{" "}
                  <p>
                    <b>Course: </b>
                    {participant.course_name}
                  </p>
                  <p>
                    <b>Coach: </b>
                    {participant.staff_name_full}
                  </p>
                  <p>
                    <b>Work Title: </b>
                    {participant.participant_work_title}
                  </p>
                  <p>
                    <b>Tenure: </b>
                    {participant.participant_work_tenure}
                  </p>
                </div>
                <h2>Consent Information</h2>
                <hr></hr>
                <div className="flex flex-column justify-content-around">
                  <p>
                    <b>
                      I have read and acknowledge the receipt of the Program
                      Terms of Service and Privacy Policies:
                    </b>{" "}
                    {participant.terms_of_service_privacy}
                  </p>
                  <p>
                    <b>The Participant gave consent to Store Data: </b>
                    {participant.consent_data_store}
                  </p>
                  <p>
                    <b>The Participant gave consent to Share data: </b>
                    {participant.consent_data_share}
                  </p>
                </div>
              </Card>
            </div>
          </div>
        </div>
      )}
      {activeTab === "Assessments" && (
        <div>
          <div>
            <div className="mt-5"></div>

            <h2 className="mt-5">Current Assessment</h2>
            <div className="flex flex-column flex-wrap gap-5">
              {participant?.participant_assessments.assessment_current?.length >
              1 ? (
                <ParticipantAssessmentScore id={idParticipant} />
              ) : (
                <div>
                  <div className="flex justify-content-between align-items-center">
                    <h3>This participant doesn't have a current Assessment</h3>
                  </div>
                </div>
              )}
            </div>

            <hr className="my-5" />
            <h1>Assessments Log</h1>
            <div className="flex flex-column flex-wrap gap-5">
              <DataTable
                paginator
                rows={10}
                value={participant.participant_assessments.assessment_log}
                resizableColumns
                showGridlines
                scrollHeight="flex"
                className="w-full	"
                style={{ tableLayout: "fixed", maxWidth: "100%" }}
              >
                <Column
                  sortable
                  field="assessment_name"
                  header="Assessment Name"
                  headerClassName="text-center"
                  style={{ maxWidth: "75px", wordWrap: "break-word" }}
                />

                <Column
                  field="assessment_date"
                  body={dateTemplate}
                  header="Date"
                  headerClassName="text-center"
                  style={{ maxWidth: "50px", wordWrap: "break-word" }}
                />

                <Column
                  className="text-center"
                  field="total_score_personal_performance"
                  header="Personal Performance"
                  headerClassName="text-center"
                  style={{ maxWidth: "57.5px", wordWrap: "break-word" }}
                />
                <Column
                  className="text-center"
                  field="total_score_satisfaction_life"
                  header="Life Satisfaction"
                  headerClassName="text-center"
                  style={{ maxWidth: "50px", wordWrap: "break-word" }}
                />
                <Column
                  className="text-center"
                  field="total_score_resilience_stress"
                  header="Resilence to Stress"
                  headerClassName="text-center"
                  style={{ maxWidth: "50px", wordWrap: "break-word" }}
                />
                <Column
                  className="text-center"
                  field="total_score_assessment"
                  header="Total Score"
                  headerClassName="text-center"
                  style={{ maxWidth: "50px", wordWrap: "break-word" }}
                />
              </DataTable>
            </div>
          </div>
        </div>
      )}
    </section>
  )
}
