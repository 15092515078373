import React from "react"
import { TabView, TabPanel } from "primereact/tabview"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { SplitButton } from "primereact/splitbutton"

export default function accounts() {
  const participants = [
    {
      name: "Marcelo rojas ",
      email: "marcelo@mail.com",
      phone: "000-000-0000",
      completedCourses: "9",
    },
    {
      name: " John Doe",
      email: "johnDoe@mail.com",
      phone: "00-000-0000",
      completedCourses: "7",
    },
    {
      name: "Janeth Doe",
      email: "janet@mail.com",
      phone: "55-555-5555",
      completedCourses: "10",
    },
    {
      name: "Marcus Dalas",
      email: "mail@mail.com",
      phone: "66-666-6666",
      completedCourses: "2",
    },
  ]

  const items = [
    {
      label: "See More",
    },
    {
      label: "Edit",
      icon: "pi pi-pencil",
    },
  ]
  const button = <SplitButton label="Options" model={items} />

  return (
    <div className="p-col-12 p-md-3">
      <TabView>
        <TabPanel header="Participants">
          <DataTable value={participants} columnResizeMode="expand" resizableColumns showGridlines>
            <Column filter field="name" header="Name"></Column>

            <Column filter field="email" header="Email" />
            <Column field="phone" header="Phone" />

            <Column field="completedCourses" header="Completed Courses" />
            <Column body={button} header="Actions" />
          </DataTable>
        </TabPanel>
      </TabView>
    </div>
  )
}
