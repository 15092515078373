import React from "react"

import styles from "../styles/Footer.module.css"

export default function Footer() {
  return (
    <div className={styles.footer}>
      <div className="flex justify-items-center align-content-center gap-4">
        <div>
          <h4>© Third Horizon Strategies 2024</h4>
        </div>
        <div className="flex gap-2">
          <a
            href="https://thirdhorizonstrategies.com/terms-of-use/"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              cursor: "pointer",
              textDecoration: "none",
              color: "inherit",
            }}
          >
            <h4 style={{ cursor: "pointer" }}>Terms of Use</h4>
          </a>
          <h4> |</h4>
          <a
            style={{
              cursor: "pointer",
              textDecoration: "none",
              color: "inherit",
            }}
            href="https://thirdhorizonstrategies.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h4 style={{ cursor: "pointer" }}>Privacy Policy</h4>
          </a>
        </div>
      </div>
    </div>
  )
}
