const resourcesOpts = [
  {
    label: "Audio",
    value: "audio",
  },
  {
    label: "Divider",
    value: "divider",
  },
  {
    label: "Editor (rich text)",
    value: "editor",
  },
  {
    label: "File",
    value: "file",
  },
  {
    label: "Plain Text",
    value: "text",
  },
  {
    label: "Shared",
    value: "shared",
  },
  {
    label: "Title",
    value: "title",
  },
  {
    label: "Video",
    value: "video",
  },
]

export default resourcesOpts
