import React from "react"
import { useParams } from "react-router-dom"

import * as Types from "../fieldTypes"
import PropTypes from "prop-types"

import TypeConditional from "../fieldTypes/TypeConditional"
import TypeSys from "../fieldTypes/System"

export default function FieldBuilder({ fields }) {
  const { idRef } = useParams()
  const isEditMode = !!idRef

  return (
    <div>
      {fields.map((field) => {
        const newProps = {
          key: field.field_name,
          field,
          isEditMode,
        }

        const isConditional = field.field_type.startsWith("conditional")

        if (isConditional) {
          return <TypeConditional {...newProps} key={field.field_name} />
        }

        switch (field.field_type) {
          case "checkbox":
            return <Types.TypeCheckbox {...newProps} />
          case "radio":
            return <Types.TypeCheckbox {...newProps} />
          case "dropdown":
            return <Types.TypeDropdown {...newProps} />
          case "dropdown_us_states":
            return <Types.TypeDropdownUsState {...newProps} />
          case "date":
            return <Types.TypeDate {...newProps} />
          case "text":
            return <Types.TypeText {...newProps} />
          case "textarea_rich":
            return <Types.TypeTextareaRich {...newProps} />
          case "number":
            return <Types.TypeNumber {...newProps} />
          case "password":
            return <Types.TypePassword {...newProps} />
          case "hidden":
            return <Types.TypeHidden {...newProps} />
          case "phone":
            return <Types.TypePhone {...newProps} />
          case "file":
            return <Types.TypeFile {...newProps} />
          case "sys":
            return <TypeSys {...newProps} />
          default: {
            console.log("Field Type Not Found:", field)
            return null
          }
        }
      })}
    </div>
  )
}

// PropTypes validation
FieldBuilder.propTypes = {
  fields: PropTypes.object.isRequired,
}
