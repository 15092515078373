import React, { useState } from "react"
import Header from "./Header"
import Footer from "./Footer"
import PropTypes from "prop-types"

export default function Layout({ children }) {
  const [toogle] = useState(true)
  const handleClassName = toogle ? "expand" : "collapse"

  Layout.propTypes = {
    children: PropTypes.node.isRequired,
  }
  return (
    <div className={`theme-wrapper ${handleClassName}`}>
      <Header />
      <div className="top-line" />
      <main className="container app-body shadow-1">{children}</main>
      <Footer />
    </div>
  )
}
