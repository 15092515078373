import React, { useContext, useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { gql, useQuery } from "@apollo/client"
import Confetti from "react-confetti"
import { UserContext } from "../../context/userContext"
import { ProgressBar } from "primereact/progressbar"
import { ListBox } from "primereact/listbox"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { LoaderMedium } from "../../components/Loaders"
import { Divider } from "primereact/divider"
import { DateTime } from "luxon"

//GA
import gATracking from "../../helpers/gaTracking"

const GET_COURSE = gql`
  query GetCourse($id: ID!, $participant_id: ID!) {
    course(id: $id) {
      title
      description
      lessons {
        id
        title
        description
      }
    }
    log: participantLessonLogByParticipantId(participant_id: $participant_id) {
      staff_id
      organization_cohort_id
      course_id
      course_lesson_id
      course_lesson_complete
      course_lesson_reflection
      date_created
    }
  }
`

function ViewCourse() {
  const navigate = useNavigate()
  const userCtx = useContext(UserContext)
  const { id: courseId } = useParams()
  const { loading, error, data } = useQuery(GET_COURSE, {
    variables: { id: courseId, participant_id: userCtx.session_id },
    fetchPolicy: "network-only",
  })

  const gaTrackingParams = {
    event: "",
    userCtx,
    payload: {},
  }

  const [showConfetti, setShowConfetti] = useState(false)
  const [progressPercentage, setProgressPercentage] = useState(0)

  useEffect(() => {
    if (data) {
      const totalLessons = data.course.lessons.length
      const completedLessonIds = data?.log
        ? data.log
            .filter((log) => log.course_id === courseId)
            .map((log) => log.course_lesson_id)
        : []

      const completedLessons = completedLessonIds.length
      const percentage = (completedLessons / totalLessons) * 100

      setProgressPercentage(percentage)

      if (percentage === 100) {
        setShowConfetti(true)
        setTimeout(() => setShowConfetti(false), 5000) // Show confetti for 5 seconds
      } else {
        setShowConfetti(false)
      }
    }
  }, [data, courseId])

  useEffect(() => {
    if (data && progressPercentage > 0) {
      console.log(data.log[0].organization_cohort_id)
      gaTrackingParams.event = "course_view"
      gaTrackingParams.payload = {
        cohort_id: data.log[0].organization_cohort_id || "",
        course_progress: progressPercentage,
        course_id: courseId,
        course_name: data.course?.title || "",
        coach_id: data.log[0].staff_id || "",
      }
      gATracking(gaTrackingParams)
    }
  }, [data, progressPercentage, courseId])

  if (loading) return <LoaderMedium />
  if (error) return <div>Error! {error.message}</div>

  const { title, description, lessons } = data.course

  const dateTemplate = (rowData) => {
    const parsedDate = DateTime.fromFormat(
      rowData.date_created,
      "yyyy-MM-dd HH:mm:ss"
    )
    return parsedDate.toFormat("MM/dd/yyyy")
  }

  const courseList = lessons.map((lesson) => {
    const isCompleted = data?.log?.some(
      (log) =>
        log.course_lesson_id === lesson.id &&
        log.course_lesson_complete &&
        log.course_id === courseId
    )

    return {
      name: (
        <div>
          {lesson.title}{" "}
          {isCompleted && (
            <i
              className={`pi pi-check-circle`}
              style={{ color: "green", marginLeft: "8px" }}
            />
          )}
        </div>
      ),
      id: lesson.id,
    }
  })

  const logs = data.log
    ? data.log
        .filter((log) => log.course_id === courseId)
        .map((log) => ({
          ...log,
          course_lesson_title: lessons.find(
            (lesson) => lesson.id === log.course_lesson_id
          )?.title,
        }))
    : []

  return (
    <div>
      {showConfetti && <Confetti />}
      <h1>{title}</h1>
      <p>{description}</p>
      <div className="flex flex-column gap-1 mb-6">
        <h3 className="mb-1">Progress:</h3>
        <ProgressBar value={Math.round(progressPercentage)} />
      </div>
      <h2>Lesson List</h2>

      <div>
        <ListBox
          onChange={(e) => {
            gaTrackingParams.event = "button_click"
            gaTrackingParams.payload = {
              button_id: "LessonListBtn",
              button_text: e.value.name.props.children[0],
              button_type: "List",
              button_location: "participant_view_course",
            }
            gATracking(gaTrackingParams)

            const lessonId = e.value.id
            navigate(`/view/course/${courseId}/lesson/${lessonId}`)
          }}
          options={courseList}
          optionLabel="name"
          className="mb-5"
        />
      </div>
      <Divider />
      <div className="w-full">
        <h3>Lesson Reflections</h3>
        <DataTable
          value={logs}
          paginator
          rows={10}
          resizableColumns
          showGridlines
          scrollHeight="flex"
          className="w-full	"
          style={{ tableLayout: "fixed", maxWidth: "100%" }}
        >
          <Column
            field="date_created"
            body={dateTemplate}
            header="Date Created"
          />
          <Column field="course_lesson_title" header="Lesson Title" />
          <Column field="course_lesson_reflection" header="Reflection" />
        </DataTable>
      </div>
    </div>
  )
}

export default ViewCourse
