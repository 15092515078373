function graphQLURL() {
  switch (process.env.REACT_APP_STAGE) {
    case "local":
      return process.env.REACT_APP_SERVICE_LOCAL_GRAPHQL_API
    case "develop":
      return process.env.REACT_APP_SERVICE_DEV_GRAPHQL_API
    case "prod":
      return process.env.REACT_APP_SERVICE_PROD_GRAPHQL_API
    default:
      return process.env.REACT_APP_SERVICE_LOCAL_GRAPHQL_API
  }
}

function userURL() {
  switch (process.env.REACT_APP_STAGE) {
    case "local":
      return process.env.REACT_APP_SERVICE_LOCAL_WB_USER_ORG_API
    case "develop":
      return process.env.REACT_APP_SERVICE_DEV_WB_USER_ORG_API
    case "prod":
      return process.env.REACT_APP_SERVICE_PROD_WB_USER_ORG_API
    default:
      return process.env.REACT_APP_SERVICE_LOCAL_WB_USER_ORG_API
  }
}

function cloudFunctionsURL() {
  switch (process.env.REACT_APP_STAGE) {
    case "local":
      return process.env.REACT_APP_SERVICE_DEV_FUNCTIONS
    case "develop":
      return process.env.REACT_APP_SERVICE_DEV_FUNCTIONS
    case "prod":
      return process.env.REACT_APP_SERVICE_PROD_FUNCTIONS
    default:
      return process.env.REACT_APP_SERVICE_DEV_FUNCTIONS
  }
}

export { graphQLURL, userURL, cloudFunctionsURL }
