export default function roundToNearestHalfOrWhole(num) {
  const roundedToHalf = Math.round(num * 2) / 2
  const roundedToWhole = Math.round(num)
  // Determine which rounding is closer
  if (Math.abs(roundedToHalf - num) < Math.abs(roundedToWhole - num)) {
    return roundedToHalf
  } else {
    return roundedToWhole
  }
}
