export default function gATracking(args) {
  const { event, payload, userCtx } = args
  if (event && userCtx) {
    window.dataLayer.push({
      event: event,
      user_id: userCtx.session_id,
      organization_id: userCtx.organization_id,
      user_role: userCtx.role_id,
      user_status: userCtx.user_status,
      ...payload,
    })
  } else {
    return
  }
}
