import React, { useContext } from "react"
import { InputText } from "primereact/inputtext"
import { Dropdown } from "primereact/dropdown"

import { AddLessonCtx } from "../../context/addLessonCtx"

export default function TypeTitle() {
  const context = useContext(AddLessonCtx)
  const { title, htmlTag } = context.newResource.content
  return (
    <div>
      <div className="flex flex-column">
        <label htmlFor="htmlTag">Heading Type</label>
        <Dropdown
          id="htmlTag"
          name="htmlTag"
          value={htmlTag}
          options={[
            { label: "Heading 1", value: "h1" },
            { label: "Heading 2", value: "h2" },
            { label: "Heading 3", value: "h3" },
          ]}
          onChange={(e) =>
            context.handleOnChange(e.target.name, e.target.value)
          }
        />
      </div>
      <div className="flex flex-column">
        <label htmlFor="title">Title</label>
        <InputText
          id="title"
          name="title"
          value={title}
          onChange={(e) =>
            context.handleOnChange(e.target.name, e.target.value)
          }
        />
      </div>
    </div>
  )
}
