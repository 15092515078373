import React, { useState, useCallback, useEffect, useContext } from "react"
import axios from "axios" // Import axios
import { useNavigate } from "react-router-dom"
import { gql, useQuery } from "@apollo/client"
import { ProgressBar } from "primereact/progressbar"
import { Button } from "primereact/button"
import { RadioButton } from "primereact/radiobutton"
import { InputText } from "primereact/inputtext" // Import InputText for text fields
import { LoaderMedium } from "../../components/Loaders"
import { Dialog } from "primereact/dialog"

import { UserContext } from "../../context/userContext"

import gATracking from "../../helpers/gaTracking"

// services
import * as services from "../../config/servicesURL"
import PropTypes from "prop-types"

const GET_ASSESSMENT = gql`
  query GetAssessment {
    template(idTemplate: "assessments") {
      blocks {
        idBlock
        block_name
        block_title
        index
        fields {
          field_label
          field_name
          field_type
          field_options {
            label
            value
          }
          field_default_value
        }
      }
    }
  }
`

const AssessmentField = React.memo(({ blockId, field, value, onChange }) => {
  const renderField = () => {
    switch (field.field_type) {
      case "radio":
        return field.field_options.map((option) => {
          const inputId = `${field.field_name}-${option.value}`
          return (
            <div key={option.value} className="flex align-items-center text-sm">
              <RadioButton
                inputId={inputId}
                name={field.field_name}
                value={option.value}
                checked={value === option.value}
                onChange={(e) => onChange(blockId, field.field_name, e.value)}
              />
              <label htmlFor={inputId} className="ml-2">
                {option.label}
              </label>
            </div>
          )
        })
      case "text":
        return (
          <InputText
            name={field.field_name}
            value={value}
            onChange={(e) =>
              onChange(blockId, field.field_name, e.target.value)
            }
          />
        )
      case "hidden":
        return null
      default:
        return null
    }
  }
  //Prop Validation
  AssessmentField.propTypes = {
    blockId: PropTypes.string.isRequired,
    field: PropTypes.shape({
      field_label: PropTypes.string.isRequired,
      field_name: PropTypes.string.isRequired,
      field_type: PropTypes.string.isRequired,
      field_options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
        })
      ),
      field_default_value: PropTypes.string,
    }).isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  return (
    <div key={field.field_name} className="mb-4 border-l-solid">
      {field.field_type !== "hidden" && (
        <label className="flex mb-2 font-bold text-sm">
          {field.field_label}
        </label>
      )}
      <div className="flex flex-wrap gap-5">{renderField()}</div>
    </div>
  )
})

const AssessmentBody = ({
  loading,
  data,
  currentBlockIndex,
  formValues,
  handleInputChange,
}) => {
  if (loading) return <LoaderMedium />
  if (!data) return null

  // Sort blocks by their index value
  const sortedBlocks = [...data.template.blocks].sort(
    (a, b) => a.index - b.index
  )
  const currentBlock = sortedBlocks[currentBlockIndex]

  return (
    <div>
      {currentBlock.fields.map((field) => (
        <AssessmentField
          key={field.field_name}
          blockId={currentBlock.idBlock}
          field={field}
          value={formValues[currentBlock.idBlock]?.[field.field_name] || ""}
          onChange={handleInputChange}
        />
      ))}
    </div>
  )
}

AssessmentBody.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.object,
  currentBlockIndex: PropTypes.number.isRequired,
  formValues: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
}

export default function Assessment({ visible, setVisible }) {
  const navigate = useNavigate()
  const userCtx = useContext(UserContext)

  const { loading, error, data } = useQuery(GET_ASSESSMENT, {
    skip: !visible,
  })
  const [currentBlockIndex, setCurrentBlockIndex] = useState(0)
  const [formValues, setFormValues] = useState({})
  const [isNextDisabled, setIsNextDisabled] = useState(true)
  const [submitting, setSubmitting] = useState(false)

  /*Google Analytics tracking*/
  const gaTrackingParams = {
    event: "",
    userCtx,
    payload: {},
  }
  /*End Google Analytics tracking*/

  useEffect(() => {
    if (visible) {
      gaTrackingParams.event = "assessment_begin"
      gATracking(gaTrackingParams)
    }
  }, [visible])

  const handleHide = () => {
    setVisible(false)
    navigate("/", { replace: true })
  }

  const handleNext = () => {
    setCurrentBlockIndex((prevIndex) => prevIndex + 1)
  }

  const handlePrevious = () => {
    setCurrentBlockIndex((prevIndex) => prevIndex - 1)
  }

  const handleInputChange = useCallback((blockId, fieldName, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [blockId]: {
        ...prevValues[blockId],
        [fieldName]: value,
      },
    }))
  }, [])

  const handleSubmit = async () => {
    const cloudFunctionsURL = services.cloudFunctionsURL()
    const postURL = `${cloudFunctionsURL}/formSubmit`

    setSubmitting(true)
    try {
      const postBody = {
        template_id: "assessments",
        participant_id: userCtx.session_id,
        values: formValues,
      }
      const response = await axios.post(postURL, postBody, {
        headers: {
          Authorization: "Bearer " + userCtx.token,
        },
      })

      //GA Tracking
      gaTrackingParams.event = "assessment_end"
      gaTrackingParams.payload = {
        assessment_id: response.data.data.assessment_id,
      }
      gATracking(gaTrackingParams)
      //end GA Tracking

      const timestamp = new Date().toISOString()
      const newUrl = response?.data?.redirect
        ? `${response.data.redirect}`
        : "/?submitted=true&timestamp=" + timestamp
      console.error("Form submission successful. Redirecting to: ", newUrl)

      //navigate(newUrl)
      window.location.href = newUrl
    } catch (error) {
      console.error("Form submission error:", error)
      alert("Error submitting the form")
    } finally {
      setSubmitting(false)
    }
  }

  useEffect(() => {
    if (!loading && data) {
      // Sort blocks by their index value
      const sortedBlocks = [...data.template.blocks].sort(
        (a, b) => a.index - b.index
      )
      const currentBlock = sortedBlocks[currentBlockIndex]
      const allFieldsFilled = currentBlock.fields.every(
        (field) => formValues[currentBlock.idBlock]?.[field.field_name]
      )
      setIsNextDisabled(!allFieldsFilled)
    }
  }, [currentBlockIndex, formValues, loading, data])

  if (error) return <div>Error in Assessment</div>

  const renderFooter = () => {
    if (loading || !data) return null
    // Sort blocks by their index value
    const sortedBlocks = [...data.template.blocks].sort(
      (a, b) => a.index - b.index
    )
    return (
      <div className="flex pt-2">
        {currentBlockIndex > 0 && (
          <Button label="Previous" onClick={handlePrevious} />
        )}
        {currentBlockIndex < sortedBlocks.length - 1 && (
          <Button
            label="Next"
            severity="success"
            onClick={handleNext}
            disabled={isNextDisabled}
          />
        )}
        {currentBlockIndex === sortedBlocks.length - 1 && (
          <Button
            label="Submit"
            onClick={handleSubmit} // Call handleSubmit on form submission
            disabled={isNextDisabled}
          />
        )}
      </div>
    )
  }

  const renderHeader = () => {
    if (loading || !data) return null
    const sortedBlocks = [...data.template.blocks].sort(
      (a, b) => a.index - b.index
    )
    const totalFields = sortedBlocks.reduce(
      (acc, block) => acc + block.fields.length,
      0
    )
    const fieldsFilled = Object.values(formValues).reduce((acc, block) => {
      if (!block) return acc
      return acc + Object.keys(block).length
    }, 0)
    const progress = Math.round((fieldsFilled / totalFields) * 100)
    const currentBlock = sortedBlocks[currentBlockIndex]

    return (
      <div>
        <h4 className="m-0 font-light">Well-Being Essentials</h4>
        <h5 className="mt-1 mb-3 font-bold">{currentBlock.block_title}</h5>
        <ProgressBar value={progress} style={{ height: 9 }} showValue={false} />
      </div>
    )
  }

  Assessment.propTypes = {
    visible: PropTypes.bool.isRequired,
    setVisible: PropTypes.func.isRequired,
  }

  return (
    <Dialog
      header={renderHeader}
      visible={visible}
      position={"top"}
      style={{ width: "65vw", height: "85vh" }}
      onHide={handleHide}
      draggable={false}
      resizable={false}
      footer={renderFooter}
    >
      {submitting ? (
        <LoaderMedium />
      ) : (
        <AssessmentBody
          loading={loading}
          data={data}
          currentBlockIndex={currentBlockIndex}
          formValues={formValues}
          handleInputChange={handleInputChange}
        />
      )}
    </Dialog>
  )
}
