import React, { useEffect, useContext } from "react"
import { useFormikContext } from "formik"

// Ctx
import { UserContext } from "../../../../context/userContext"
import propTypes from "prop-types"

export default function TypeCreatedBy({ field }) {
  const formik = useFormikContext()
  const user = useContext(UserContext)

  useEffect(() => {
    formik.setFieldValue(field.field_name, user.session_id)
  }, [])

  return (
    <div style={{ display: "none" }}>
      <input
        type="hidden"
        name={field.field_name}
        value={formik.values[field.field_name]}
      />
    </div>
  )
}

TypeCreatedBy.propTypes = {
  field: propTypes.shape({
    field_name: propTypes.string.isRequired,
  }).isRequired,
}
