import { useEffect } from "react"
import { useFormikContext } from "formik"
import _ from "lodash"
import * as Types from "../index"

import propTypes from "prop-types"
export default function TypeConditional(props) {
  const { values, initialValues, setFieldValue } = useFormikContext()

  const typeSplit = _.split(props.field.field_type, "_")
  const type = typeSplit[1]

  const parseStringToObj = (string) => {
    try {
      return JSON.parse(string)
    } catch {
      return {}
    }
  }

  const { field_conditional, field_name } = props.field

  const conditionalFieldObj =
    !_.isEmpty(field_conditional) && parseStringToObj(field_conditional)

  const getConditionalFieldKey = () => {
    const keyList = Object.keys(conditionalFieldObj)
    return keyList[0]
  }

  const conditionalFieldKey = getConditionalFieldKey()
  const conditionalFieldValue = conditionalFieldObj[conditionalFieldKey]

  const displayField = _.reduce(
    conditionalFieldValue,
    (acc, option) => {
      if (
        conditionalFieldValue[0] === "*" &&
        values[conditionalFieldKey] !== ""
      ) {
        return true
      }
      return acc || _.includes(values[conditionalFieldKey], option)
    },
    false
  )

  useEffect(() => {
    // Reset the value of the field if displayField is false
    if (!displayField) {
      setFieldValue(field_name, initialValues[field_name])
    }
  }, [displayField, field_name, initialValues, setFieldValue])

  if (!displayField) return null

  switch (type) {
    case "text":
      return <Types.TypeText {...props} />
    case "dropdown":
      return <Types.TypeDropdown {...props} />
    case "checkbox":
      return <Types.TypeCheckbox {...props} />
    case "phone":
      return <Types.TypePhone {...props} />
    default:
      return null
  }
}

TypeConditional.propTypes = {
  field: propTypes.object,
}
