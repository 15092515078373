import React, { useContext, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { UserContext } from "../../context/userContext"
import { useQuery, gql } from "@apollo/client"
import { LoaderMedium } from "../../components/Loaders"
import { DateTime } from "luxon"
import { Toast } from "primereact/toast"
import axios from "axios"
import * as services from "../../config/servicesURL"

// Prime React
import { Card } from "primereact/card"
import { Button } from "primereact/button"

const GET_PARTICIPANT = gql`
  query Participant($participant_id: ID!) {
    participant(participant_id: $participant_id) {
      participant_id

      participant_name_full
      participant_email
      participant_status
      demo_dob
      consent_data_store
      consent_data_share
      participant_timezone

      participant_sms_phone
      participant_work_title
      participant_work_tenure
      terms_of_service_privacy
      participant_improvement_focus_primary
      participant_improvement_focus_secondary
      participant_readiness_change_habits
      participant_readiness_change_support
      participant_motivation

      participant_profile_image_link_400x400
      demo_gender
      demo_education_level
      demo_relationship_status
      demo_race
      demo_ethnicity

      organization_cohort_name
      course_id
      course_name
      staff_id
      staff_name_full

      organization_name

      participant_assessments {
        assessment_current {
          assessment_date
          total_score_personal_performance
          total_score_satisfaction_life
          total_score_resilience_stress
          total_score_assessment
        }

        assessment_log {
          assessment_name
          participant_id
          assessment_date
          total_score_personal_performance
          total_score_satisfaction_life
          total_score_resilience_stress
          total_score_assessment
        }
      }
    }
  }
`

export default function ParticipantHomeProfile() {
  const idParticipant = useContext(UserContext)
  const toast = useRef(null)

  const navigate = useNavigate()

  const { loading, error, data } = useQuery(GET_PARTICIPANT, {
    variables: { participant_id: idParticipant.session_id },
    fetchPolicy: "network-only",
    skip: !idParticipant.session_id,
  })

  if (loading) return <LoaderMedium />
  if (error) return <div>Error! {error.message}</div>

  const participant = data.participant

  const resetPassword = async () => {
    try {
      const USERS_URL = services.userURL()

      const RESET_PASSWORD = `${USERS_URL}/auth/password-reset/${idParticipant.session_id}`
      const response = await axios.get(RESET_PASSWORD, {
        headers: {
          Authorization: `Bearer ${idParticipant.token}`,
        },
      })
      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Your email to change your password has been sent ",
        })
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "There was an error sending the email",
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const parseText = (string) => {
    const tempString = document.createElement("div")
    tempString.innerHTML = string
    return tempString.textContent || tempString.innerText || ""
  }

  return (
    <div>
      <Toast ref={toast} />

      <div className="flex flex-column mt-5 gap-5">
        <div className="flex gap-5">
          <div className="flex flex-column gap-5">
            <Card
              className="w-30rem shadow-3"
              style={{
                height: "52rem",
                border: "solid 1px #3175bb",
              }}
            >
              <div className="flex justify-content-center">
                <img
                  alt="Participant Profile"
                  className="w-20rem h-20rem my-3"
                  style={{ borderRadius: "50%", objectFit: "cover" }}
                  src={participant.participant_profile_image_link_400x400}
                />
              </div>
              <h2>Personal Profile</h2>
              <hr></hr>
              <div className="flex flex-column justify-content-around">
                <p>
                  <b>Name: </b>
                  {participant.participant_name_full}
                </p>
                <p>
                  <b>Email: </b>
                  {participant.participant_email}
                </p>
                <p>
                  <b>Phone Number: </b>
                  {participant.participant_sms_phone}
                </p>

                <p>
                  <b>Birth Date: </b>
                  {DateTime.fromISO(participant.demo_dob).toFormat(
                    "MM/dd/yyyy"
                  )}
                </p>

                <p>
                  <b>Time Zone: </b>
                  {participant.participant_timezone}
                </p>
                <div className="flex justify-content-between mt-4 align-items-center">
                  <Button
                    label="Edit"
                    onClick={() =>
                      navigate(
                        `/form/edit/profile/1/${idParticipant.session_id}`
                      )
                    }
                  />
                  <button
                    className="border-none underline p-0 cursor-pointer text-blue-600 text-base mt-2"
                    style={{
                      backgroundColor: "transparent",
                    }}
                    type="button"
                    onClick={(e) => {
                      e.preventDefault()
                      resetPassword()
                    }}
                  >
                    Reset Password
                  </button>
                </div>
              </div>
            </Card>
            <Card
              className="w-30rem shadow-3"
              style={{
                height: "25rem",
                border: "solid 1px #3175bb",
              }}
            >
              <h2>Demographic Information</h2>
              <hr />
              <div className="flex flex-column justify-content-around">
                <p>
                  <b>Gender: </b>
                  {participant.demo_gender}
                </p>
                <p>
                  <b>Education: </b>
                  {participant.demo_education_level}
                </p>
                <p>
                  <b>Relationship: </b>
                  {participant.demo_relationship_status}
                </p>
                <p>
                  <b>Race: </b>
                  {participant.demo_race.join(", ")}{" "}
                </p>
                <p>
                  <b>Ethnicity: </b>
                  {participant.demo_ethnicity.join(", ")}{" "}
                </p>
              </div>
            </Card>
          </div>
          <Card
            className="shadow-3"
            style={{
              width: "50rem",
              border: "solid 1px #21becb",
            }}
          >
            <h2>Motivation</h2>
            <hr></hr>
            <div className="flex flex-column justify-content-around">
              <p>
                <b>Primary Focus: </b>{" "}
                {participant.participant_improvement_focus_primary}
              </p>
              <p>
                <b>Secondary Focus: </b>{" "}
                {participant.participant_improvement_focus_secondary}
              </p>
              <p>
                <b>The present participant is ready to change their habits?:</b>{" "}
                {participant.participant_readiness_change_habits}
              </p>
              <p>
                <b>The present participant is ready to receive support?:</b>{" "}
                {participant.participant_readiness_change_support}
              </p>
              <p>
                <b>
                  Personal motivation and what the participant is hoping to
                  learn:
                </b>
                {" " + parseText(participant.participant_motivation)}
              </p>
            </div>
            <h2 className="mt-4">Professional Information</h2>
            <hr></hr>
            <div className="flex flex-column justify-content-around">
              <p>
                <b>Status: </b>
                {participant.participant_status}
              </p>{" "}
              <p>
                <b>Organization: </b>
                {participant.organization_name}
              </p>
              <p>
                <b>Cohort: </b>
                {participant.organization_cohort_name}
              </p>{" "}
              <p>
                <b>Course: </b>
                {participant.course_name}
              </p>
              <p>
                <b>Coach: </b>
                {participant.staff_name_full}
              </p>
              <p>
                <b>Work Title: </b>
                {participant.participant_work_title}
              </p>
              <p>
                <b>Tenure: </b>
                {participant.participant_work_tenure}
              </p>
            </div>
            <h2>Consent Information</h2>
            <hr></hr>
            <div className="flex flex-column justify-content-around">
              <p>
                <b>
                  I have read and acknowledge the receipt of the Program Terms
                  of Service and Privacy Policies:
                </b>{" "}
                {participant.terms_of_service_privacy}
              </p>
              <p>
                <b>The Participant gave consent to Store Data: </b>
                {participant.consent_data_store}
              </p>
              <p>
                <b>The Participant gave consent to Share data: </b>
                {participant.consent_data_share}
              </p>
            </div>
          </Card>
        </div>
      </div>
    </div>
  )
}
