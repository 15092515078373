import React, { useState } from "react"
import _ from "lodash"
import { gql, useQuery, useMutation } from "@apollo/client"

import { useNavigate } from "react-router-dom"

import { PickList } from "primereact/picklist"
import { Button } from "primereact/button"
import { InputTextarea } from "primereact/inputtextarea"
import { InputText } from "primereact/inputtext"
import { Chips } from "primereact/chips"
import { Badge } from "primereact/badge"

import { LoaderMedium } from "../../components/Loaders"

const GET_LESSON_LIST = gql`
  query GetLessonList {
    lessons {
      id
      title
      tags
      resources {
        id
      }
    }
  }
`

const ADD_COURSE = gql`
  mutation AddCourse(
    $title: String!
    $description: String
    $tags: [String]
    $lessons: [ID]
  ) {
    addCourse(
      title: $title
      description: $description
      tags: $tags
      lessons: $lessons
    ) {
      id
    }
  }
`

export default function AddCourse() {
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [tags, setTags] = useState([])

  const [source, setSource] = useState([])
  const [target, setTarget] = useState([])

  const navigate = useNavigate()

  const { loading, data } = useQuery(GET_LESSON_LIST, {
    onCompleted: () => {
      setSource(data.lessons)
    },
  })

  const [addCourse] = useMutation(ADD_COURSE, {
    variables: {
      title,
      description,
      tags,
      lessons: target.map(({ id }) => id),
    },
    onCompleted: () => {
      navigate("/list/courses")
    },
  })

  const onChange = (event) => {
    setSource(event.source)
    setTarget(event.target)
  }

  const itemTemplate = (item) => {
    return (
      <div className="flex flex-wrap p-2 align-items-center gap-3">
        <div className="flex-1 flex flex-column gap-2">
          <span className="font-bold">{item.title}</span>
          <div className="flex align-items-center gap-2">
            <i className="pi pi-tag text-sm"></i>
            <span>{item.tags?.join(", ")}</span>
          </div>
        </div>
        <Badge value={item.resources.length} />
        Resources
      </div>
    )
  }

  if (loading) return <LoaderMedium />

  return (
    <div>
      <h1>New Course</h1>
      <div className="flex flex-column gap-4 mb-5">
        <div className="flex flex-column">
          <label htmlFor="title">Title</label>
          <InputText
            id="title"
            name="title"
            onChange={(e) => setTitle(e.target.value)}
          />
          <small>Any aditional description goes here.</small>
        </div>

        <div className="flex flex-column">
          <label htmlFor="description">Description</label>
          <InputTextarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows={5}
            cols={30}
          />
        </div>

        <div className="flex flex-column">
          <label htmlFor="tags">Tags</label>
          <Chips
            value={tags}
            onChange={(e) => setTags(e.value)}
            separator=","
          />
          <small>Comma separated tags.</small>
        </div>
      </div>

      <PickList
        dataKey="id"
        source={source}
        target={target}
        onChange={onChange}
        itemTemplate={itemTemplate}
        breakpoint="1280px"
        sourceHeader="Lessons"
        targetHeader="Selected"
        sourceStyle={{ height: "24rem" }}
        targetStyle={{ height: "24rem" }}
      />
      <Button
        label="Save"
        className="p-button-success mt-4"
        onClick={addCourse}
        disabled={
          _.isEmpty(title) || _.isEmpty(description) || _.isEmpty(target)
        }
      />
    </div>
  )
}
