import React, { useContext, useEffect } from "react"
import { gql, useLazyQuery } from "@apollo/client"

import { Field, useFormikContext } from "formik"
import { LoaderMedium } from "../../../../components/Loaders"

// PR
import { Dropdown } from "primereact/dropdown"

// Ctx
import { FormContext } from "../../context/FormContext"
import propTypes from "prop-types"

const PARTICIPANT_LIST = gql`
  query participantsByCohort($organization_cohort_id: ID!) {
    participantsByCohort(organization_cohort_id: $organization_cohort_id) {
      participant_id
      participant_name_full
    }
  }
`

export default function TypeNoteParticipantId({ field }) {
  const form = useContext(FormContext)
  const formik = useFormikContext()

  const cohortId = formik.values.organization_cohort_id || null

  const blockCurrent = form.blockCurrent

  const isDisabled = form
    ? form.blocksSaved[blockCurrent.block_name] || field.field_disabled_on_edit
    : false
  const isRequired = field.field_validation?.required?.[0] === "true"

  const [getParticipant, { loading, error, data }] = useLazyQuery(
    PARTICIPANT_LIST,
    {
      variables: {
        organization_cohort_id: cohortId,
      },
    }
  )

  useEffect(() => {
    if (cohortId) {
      getParticipant()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cohortId])

  if (loading) return <LoaderMedium />
  if (error) return <div>Error! {error.message}</div>

  if (!data) return null

  const participantList = data?.participantsByCohort
    ? data.participantsByCohort.map((participant) => {
        return {
          label: participant.participant_name_full,
          value: participant.participant_id,
        }
      })
    : []

  return (
    <Field name={field.field_name}>
      {({ field: formikField, meta, form: formik }) => {
        return (
          <div className="flex flex-column gap-1 mb-5">
            <label htmlFor={formikField.name}>
              {field.field_label}
              {isRequired && "*"}
            </label>
            <Dropdown
              disabled={isDisabled || !cohortId}
              id={field.field_name}
              name={formikField.name}
              value={formikField.value}
              onChange={formikField.onChange}
              onBlur={() => formik.setFieldTouched(formikField.name)}
              options={participantList}
            />
            {field.field_description && (
              <small>{field.field_description}</small>
            )}
            {meta.touched && meta.error && (
              <div className="text-sm text-red-600 font-semibold">
                {meta.error}
              </div>
            )}
          </div>
        )
      }}
    </Field>
  )
}

TypeNoteParticipantId.propTypes = {
  field: propTypes.shape({
    field_name: propTypes.string.isRequired,
    field_label: propTypes.string.isRequired,
    field_description: propTypes.string,
    field_disabled_on_edit: propTypes.string,
    field_validation: propTypes.object,
    field_default_value: propTypes.string,
  }),
}
