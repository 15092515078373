import React, { useState } from "react"

import { storage as firebaseStorage } from "../firebase"

export default function useStorageFileUploader(path, fileType, gs = "") {
  const [progress, setProgress] = useState(0)
  const [gsRef, setGsRef] = useState(gs)

  const [timestampPrefix] = useState(Date.now())

  const { getStorage, ref, uploadBytesResumable, deleteObject } =
    firebaseStorage

  const storage = getStorage()

  const FOLDER_STRUCT = `/${path}`

  const removeHandler = async () => {
    if (gsRef === "") return
    const currentFilename = gsRef.split("/").pop()
    const fullFilePath = `${FOLDER_STRUCT}/${currentFilename}`
    const fileRef = ref(storage, fullFilePath)
    try {
      await deleteObject(fileRef)
      setProgress(0)
      setGsRef("")
    } catch (error) {
      console.log(error) // please handle ERR!!
    }
  }

  const customUploadHandler = (e) => {
    const [file] = e.files
    const uniqueFilename = `${timestampPrefix}_${file.name}`
    const path = `${FOLDER_STRUCT}/${uniqueFilename}`
    const storageRef = ref(storage, path)
    const metadata = {
      contentType: file?.type || fileType,
    }
    const uploadTask = uploadBytesResumable(storageRef, file, metadata)
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        setProgress(Math.round(progress))
      },
      (error) => {
        switch (error.code) {
          case "storage/object-not-found":
            console.log("storage/object-not-found", error)
            // File doesn't exist
            break
          case "storage/unauthorized":
            console.log("storage/unauthorized", error)
            // User doesn't have permission to access the object
            break
          case "storage/canceled":
            console.log("storage/canceled", error)
            // User canceled the upload
            break
          default:
            console.log(error)
        }
      },
      () => {
        const { fullPath } = uploadTask.snapshot.metadata
        setGsRef(fullPath)
      }
    )
  }

  const clear = () => {
    setProgress(0)
    setGsRef("")
  }

  return [customUploadHandler, removeHandler, clear, progress, gsRef]
}
