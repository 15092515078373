import { Tag } from "primereact/tag"

const statusBodyTemplate = (rowData) => {
  const bgColor = () => {
    switch (rowData.type) {
      case "audio":
        return "var(--blue-400)"
      case "video":
        return "var(--green-400)"
      case "text":
        return "var(--bluegray-500)"
      case "title":
        return "var(--yellow-400)"
      case "editor":
        return "var(--purple-400)"
      default:
        return "var(--bluegray-300)"
    }
  }
  return (
    <Tag style={{ background: bgColor() }}>
      <div className="flex align-items-center gap-2">
        <span className="text-base">{rowData.type}</span>
      </div>
    </Tag>
  )
}

export default statusBodyTemplate
