import React from "react"
import { ProgressSpinner } from "primereact/progressspinner"
import { ProgressBar } from "primereact/progressbar"

export function LoaderSmall() {
  return (
    <ProgressSpinner
      style={{ width: "30px", height: "30px" }}
      strokeWidth="4"
      animationDuration=".2s"
    />
  )
}

export function LoaderProgressBarSideWide() {
  return (
    <div style={{ position: "absolute", top: 0, width: "100vw", zIndex: 9999 }}>
      <ProgressBar mode="indeterminate" style={{ height: "3px" }}></ProgressBar>
    </div>
  )
}

export function LoaderProgressBar() {
  return (
    <div>
      <ProgressBar mode="indeterminate" style={{ height: "3px" }}></ProgressBar>
    </div>
  )
}

export function LoaderMedium() {
  return (
    <div className="flex align-content-center">
      <ProgressSpinner
        style={{ width: "50px", height: "50px" }}
        strokeWidth="4"
        animationDuration=".4s"
      />
    </div>
  )
}

export default function LoaderFullPage() {
  return <ProgressSpinner />
}
