import React, { useRef, useEffect, useState, useContext } from "react"
import { useQuery, useMutation, useLazyQuery, gql } from "@apollo/client"
import { LoaderMedium } from "../../components/Loaders"
import { Link, useNavigate } from "react-router-dom"
import { UserContext } from "../../context/userContext"
//PR
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { SplitButton } from "primereact/splitbutton"
import { Toast } from "primereact/toast"

//Ga
import gATracking from "../../helpers/gaTracking"
import getActualTime from "../../helpers/getActualTime"

import propTypes from "prop-types"
const GET_PARTICIPANTS = gql`
  query Participants {
    participants: participants {
      participant_id
      participant_name_full
      participant_email
      organization_name
      organization_cohort_name
      organization_id
      participant_sms_phone
      participant_status
    }
  }
`
const GET_STAFF_BY_PARTICIPANT = gql`
  query Participant($participant_id: ID!) {
    participant(participant_id: $participant_id) {
      staff_name_full
    }
  }
`
const DELETE_PARTICIPANT = gql`
  mutation deleteParticipant($participant_id: ID!) {
    deleteParticipant(participant_id: $participant_id) {
      message
    }
  }
`

const StaffTemplate = ({
  participant_id,
  participant_status,
  setStaffData,
}) => {
  const [getStaff, { loading, data }] = useLazyQuery(GET_STAFF_BY_PARTICIPANT, {
    variables: { participant_id },
    onCompleted: (data) => {
      setStaffData((prevState) => ({
        ...prevState,
        [participant_id]: data?.participant?.staff_name_full || "Pending",
      }))
    },
  })

  useEffect(() => {
    if (participant_status === "Active") {
      getStaff()
    } else {
      setStaffData((prevState) => ({
        ...prevState,
        [participant_id]: "Pending",
      }))
    }
  }, [getStaff, participant_status])

  if (loading) return <span>Loading...</span>

  return <span>{data?.participant?.staff_name_full || "Pending"}</span>
}

export default function ParticipantListAll() {
  const userCtx = useContext(UserContext)

  const navigate = useNavigate()
  const toast = useRef(null)
  const [staffData, setStaffData] = useState({})
  const date = getActualTime()

  const { loading, error, data } = useQuery(GET_PARTICIPANTS, {
    fetchPolicy: "network-only",
  })
  const [deleteParticipant, { data: mutationData, error: mutationError }] =
    useMutation(DELETE_PARTICIPANT)

  useEffect(() => {
    if (mutationData?.deleteParticipant?.message) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: mutationData.deleteParticipant.message,
        life: 3000,
      })
    } else if (mutationError) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: mutationError.message,
        life: 3000,
      })
    }
  }, [mutationData, mutationError])
  if (loading) return <LoaderMedium />
  if (error) return <div>Error! {error.message}</div>
  const participants = data.participants

  /*Google Analytics tracking*/
  const gaTrackingParams = {
    event: "button_click",
    userCtx,
    payload: {},
  }
  /*End Google Analytics tracking*/
  // DataTable Functions

  const staffTemplate = (rowData) => {
    return (
      <StaffTemplate
        participant_id={rowData.participant_id}
        participant_status={rowData.participant_status}
        setStaffData={setStaffData}
      />
    )
  }

  // Name Link to Profile
  const nameTemplate = (rowData) => {
    return (
      <Link
        style={{
          textDecoration: "underline",
          color: "var( --branded-light-green",
          cursor: "pointer",
        }}
        onClick={() => {
          gaTrackingParams.payload = {
            button_name: "click_view_name",
            button_text: rowData.participant_name_full,
            button_type: "link_click",
            button_location: "/list/all-participant",
          }
          gATracking(gaTrackingParams)
        }}
        to={`/participant/profile/${rowData.participant_id}`}
      >
        {rowData.participant_name_full}
      </Link>
    )
  }

  const statusTemplate = (rowData) => {
    if (rowData.participant_status === "Active") {
      return <p className="text-green-300	">{rowData.participant_status}</p>
    }
    if (rowData.participant_status === "Awaiting") {
      return <p className="text-yellow-500		">{rowData.participant_status}</p>
    }
    return <p className="text-red-500		">{rowData.participant_status}</p>
  }
  // Actions Button
  const action = (rowData) => {
    const { participant_id } = rowData
    const items = [
      {
        label: "Delete",
        icon: "pi pi-trash",
        command: () => {
          gaTrackingParams.event = "deleted"
          gaTrackingParams.payload = {
            organization_id: rowData.organization_id,
            content_id: participant_id,
            content_type: "participant",
            timestamp: date,
          }
          gATracking(gaTrackingParams)

          deleteParticipant({
            variables: { participant_id },
            refetchQueries: [{ query: GET_PARTICIPANTS }],
          })
        },
      },
    ]
    return (
      <SplitButton
        label="Edit"
        model={items}
        onClick={() => {
          gaTrackingParams.event = "Edit"
          gaTrackingParams.payload = {
            organization_id: rowData.organization_id,
            content_id: participant_id,
            content_type: "participant",
            timestamp: date,
          }
          gATracking(gaTrackingParams)

          navigate(`/form/edit/profile/1/${participant_id}`)
        }}
      />
    )
  }

  return (
    <div>
      <h1 className="my-3">All Participants</h1>
      <Toast ref={toast} />

      <div style={{ overflowX: "hidden", maxWidth: "100%" }}>
        <DataTable
          paginator
          rows={10}
          //Sorting Staff
          value={participants.map((participant) => ({
            ...participant,
            staff_name_full: staffData[participant.participant_id] || "Pending",
          }))}
          resizableColumns
          showGridlines
          scrollHeight="flex"
          className="w-full	"
          style={{ tableLayout: "fixed", maxWidth: "100%" }}
        >
          <Column
            sortable
            field="organization_name"
            header="Organization"
            className="white-space-normal	overflow-hidden	"
            style={{ maxWidth: "200px", wordWrap: "break-word" }}
          />
          <Column
            header="Cohort"
            sortable
            field="organization_cohort_name"
            className="white-space-normal	overflow-hidden	"
            style={{ maxWidth: "200px", wordWrap: "break-word" }}
          />
          <Column
            sortable
            field="participant_name_full"
            header="Name"
            body={nameTemplate}
            className="white-space-normal	overflow-hidden	"
            style={{ maxWidth: "200px", wordWrap: "break-word" }}
          />
          <Column
            sortable
            header="Email"
            field="participant_email"
            className="white-space-normal	overflow-hidden	"
            style={{ maxWidth: "250px", wordWrap: "break-word" }}
          />
          <Column
            sortable
            field="staff_name_full"
            header="Assigned Coach"
            body={staffTemplate}
            className="white-space-normal overflow-hidden"
            style={{ maxWidth: "200px", wordWrap: "break-word" }}
          />
          <Column
            sortable
            field="participant_status"
            header="Status"
            body={statusTemplate}
            className="white-space-normal	overflow-hidden	"
            style={{ maxWidth: "150px", wordWrap: "break-word" }}
          />
          <Column
            body={(rowData) => action(rowData)}
            header="Action"
            className="white-space-normal overflow-hidden"
            style={{ maxWidth: "200px", wordWrap: "break-word" }}
          />
        </DataTable>
      </div>
    </div>
  )
}

StaffTemplate.propTypes = {
  participant_id: propTypes.string.isRequired,
  participant_status: propTypes.string.isRequired,
  setStaffData: propTypes.func.isRequired,
}
