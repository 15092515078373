import React, { useContext, useEffect, useState } from "react"
import { Route, Routes, Navigate } from "react-router-dom"
import axios from "axios"
import _ from "lodash"

import { LoaderMedium } from "../../components/Loaders"

import { GET } from "../../config/constants"

// services
import * as services from "../../config/servicesURL"

import { routesDef } from "./routesDef"
import { UserContext } from "../../context/userContext"
import PropTypes from "prop-types"

// Function to check permissions using a service
const checkPermissions = async (token, route) => {
  const USERS_URL = services.userURL()
  const PERMISSIONS_ENDPOINT = `${USERS_URL}/${GET.ROUTES_PERMISSIONS}/`
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const url = window.location.href
  const parsedUrl = new URL(url)
  const params = {
    route: parsedUrl.pathname,
  }
  try {
    const response = await axios.post(PERMISSIONS_ENDPOINT, params, config)
    return response.status === 200
  } catch (error) {
    console.error("Error checking permissions:", error)
    return false
  }
}

function RequireAdmin(props) {
  const user = useContext(UserContext)
  const [loading, setLoading] = useState(true)
  const [hasPermissions, setHasPermissions] = useState(true)

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        // Check permissions when component mounts
        const permissions = await checkPermissions(user.token, props.route)
        setHasPermissions(permissions)
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }

    fetchPermissions()
  }, [user, props.route])

  if (loading) {
    // Display a loading indicator while checking permissions
    return <LoaderMedium />
  }

  if (!hasPermissions) {
    // Redirect to denied page if user doesn't have permissions
    return <Navigate to="/denied" replace />
  }

  // Render children if user has permissions
  return <>{props.children}</>
}

RequireAdmin.propTypes = {
  route: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default function RoutesBuilder() {
  const getRouteElements = (routesDef) => {
    return _.map(routesDef, (route, i) => {
      const { requireAdmin } = route

      // return <Route key={i} path={route.path} element={route.element} /> // TEMP

      const element = requireAdmin ? (
        <RequireAdmin key={route.path} route={route.path}>
          {route.element}
        </RequireAdmin>
      ) : (
        route.element
      )
      return <Route key={i} path={route.path} element={element} />
    })
  }

  function handleRoutes() {
    const routeList = getRouteElements(routesDef)
    return <Routes>{routeList}</Routes>
  }

  return <>{handleRoutes()}</>
}
