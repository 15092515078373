import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useQuery, gql, useLazyQuery } from "@apollo/client"
import { UserContext } from "../../context/userContext"

import { LoaderMedium } from "../../components/Loaders"

import { Button } from "primereact/button"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { ProgressBar } from "primereact/progressbar"

const GET_LESSONS_PER_COURSE = gql`
  query GetCourse($id: ID!) {
    course(id: $id) {
      id
      lessons {
        id
      }
    }
  }
`

const GET_PARTICIPANT_COURSES = gql`
  query ParticipantCourses($participant_id: ID!) {
    list: organizationCohortsByParticipant(participant_id: $participant_id) {
      course_name
      course_id
      organization_name
    }
    current: participant(participant_id: $participant_id) {
      course_id
    }
    log: participantLessonLogByParticipantId(participant_id: $participant_id) {
      course_id
      course_lesson_id
      course_lesson_complete
    }
  }
`

export default function ParticipantCourses() {
  const userCtx = useContext(UserContext)
  const navigate = useNavigate()

  const [coursesWithLessons, setCoursesWithLessons] = useState([])

  const { loading, error, data } = useQuery(GET_PARTICIPANT_COURSES, {
    variables: { participant_id: userCtx.session_id },
    fetchPolicy: "network-only",
    skip: !userCtx.session_id,
  })

  const [getLessons] = useLazyQuery(GET_LESSONS_PER_COURSE)

  useEffect(() => {
    if (data?.list) {
      const coursePromises = data.list.map((course) =>
        getLessons({ variables: { id: course.course_id } })
      )

      Promise.all(coursePromises).then((responses) => {
        const coursesWithLessonsData = responses.map((response) => {
          const courseId = response.data.course.id
          const lessonCount = response.data.course.lessons.length
          return { courseId, lessonCount }
        })
        setCoursesWithLessons(coursesWithLessonsData)
      })
    }
  }, [data, getLessons])

  if (loading) return <LoaderMedium />
  if (error) return <div>Error! {error.message}</div>

  const courseList = data.list || []
  const currentCourseId = data.current?.course_id
  const lessonLog = data.log || []

  // Function to calculate number of lessons completed for a course
  const getLessonsCompleted = (courseId) => {
    return lessonLog.filter(
      (log) => log.course_id === courseId && log.course_lesson_complete
    ).length
  }

  // Function to calculate progress percentage
  const getProgressPercentage = (courseId) => {
    const course = coursesWithLessons.find(
      (course) => course.courseId === courseId
    )
    if (!course) {
      return 0
    }
    const totalLessons = course.lessonCount || 0
    const lessonsCompleted = getLessonsCompleted(courseId)

    return (lessonsCompleted / totalLessons) * 100
  }

  const action = (row) => {
    return (
      <Button
        onClick={() => navigate(`/view/course/${row.course_id}`)}
        label="Go to course"
      />
    )
  }

  const courseNameTemplate = (rowData) => {
    const { course_name, course_id } = rowData
    const isCurrentCourse = course_id === currentCourseId

    return (
      <div className={isCurrentCourse ? "font-semibold" : ""}>
        {course_name}
        {isCurrentCourse && <i className="pi pi-star ml-2" />}
      </div>
    )
  }

  const lessonsCompletedTemplate = (rowData) => {
    const { course_id } = rowData
    const lessonsCompleted = getLessonsCompleted(course_id)

    return <div>{lessonsCompleted}</div>
  }

  const progressBarTemplate = (rowData) => {
    const { course_id } = rowData
    const progressValue = getProgressPercentage(course_id)

    return <ProgressBar value={progressValue} showValue={false} />
  }

  return (
    <div>
      <h1>Courses</h1>
      {courseList.length > 0 ? (
        <DataTable
          value={courseList}
          columnResizeMode="expand"
          resizableColumns
          showGridlines
        >
          <Column
            field="course_name"
            header="Course"
            body={courseNameTemplate}
          />
          <Column field="organization_name" header="Organization" />
          <Column
            field="course_id" // Use course_id as field for unique identifier
            header="Lessons Completed"
            body={lessonsCompletedTemplate}
            className="text-center"
          />
          <Column
            header="Progress"
            body={progressBarTemplate}
            className="text-center"
          />
          <Column
            body={(row) => action(row)}
            header="Action"
            className="white-space-normal overflow-hidden"
          />
        </DataTable>
      ) : (
        <div>
          <h3>Oh! It seems like there's nothing here</h3>{" "}
          <p>
            It looks like you don't have any courses to show here at the moment.
            Please check later or contact your coach
          </p>
        </div>
      )}
    </div>
  )
}
