import { gql } from "@apollo/client"

const GET_STAFF_IMG = gql`
  query Staff($staff_id: ID!) {
    staff(staff_id: $staff_id) {
      staff_profile_image_link_32x32
    }
  }
`
const GET_PARTICIPANTS_IMG = gql`
  query Participant($participant_id: ID!) {
    participant(participant_id: $participant_id) {
      participant_profile_image_link_32x32
    }
  }
`

export { GET_STAFF_IMG, GET_PARTICIPANTS_IMG }
