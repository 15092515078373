import React, { useState, useContext } from "react"
import _ from "lodash"
import { useQuery, gql } from "@apollo/client"
import { Dropdown } from "primereact/dropdown"
import { LoaderMedium } from "../../../../components/Loaders"
import { AddLessonCtx } from "../../context/addLessonCtx"

const GET_RESOURCES = gql`
  {
    resources {
      id
      content {
        description
        htmlTag
        gs
        title
        value
      }
    }
  }
`

export default function TypeShared() {
  const [selectedResource, setSelectedResource] = useState(null)
  const context = useContext(AddLessonCtx)

  const { loading, error, data } = useQuery(GET_RESOURCES, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (context.newResource) {
        const foundResource = data.resources.find(
          (resource) => resource.id === context.newResource.id
        )
        if (foundResource) {
          setSelectedResource(foundResource.id)
        }
      }
    },
  })

  if (loading) return <LoaderMedium />
  if (error) return <div>Error: {error.message}</div>

  const options = data.resources
    ? data.resources.map((resource) => ({
        label: resource.content.title,
        value: resource.id,
      }))
    : []

  const renderDescription = () => {
    if (_.isEmpty(selectedResource)) return null
    const resource = data.resources.find((res) => res.id === selectedResource)

    return (
      <div>
        <h4>Description</h4>
        <p>{resource.content.description}</p>
      </div>
    )
  }

  return (
    <div>
      <h4>Please select the shared resource you would like to use.</h4>
      <Dropdown
        options={options}
        onChange={(e) => {
          setSelectedResource(e.value)
          context.handleSharedOnChange(e.value)
        }}
        value={selectedResource}
      />
      {renderDescription()}
    </div>
  )
}
