import React, { useState, useEffect } from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import axios from "axios"
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  from,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { removeTypenameFromVariables } from "@apollo/client/link/remove-typename"

import { auth, db } from "../firebase"
import { UserContext } from "../context/userContext"

import Layout from "../layout"
import Login from "../pages/Login"
import ThankYou from "../pages/ThankYou/"
import Registration from "../pages/Form/"
import ErrorPage from "../pages/Error"
import RoutesBuilder from "../components/Routes"
import ROUTES from "../components/Routes/routesConstants"

import * as services from "../config/servicesURL"
import { GET } from "../config/constants"

function App() {
  const [user, setUser] = useState(null)

  useEffect(() => {
    const getUserProfile = async (uid) => {
      const USERS_URL = services.userURL()
      const PROFILE_ENDPOINT = `${USERS_URL}/${GET.ADMIN_USER_PROFILE}/${uid}`
      try {
        const fetchUserProfile = await axios.get(PROFILE_ENDPOINT)
        const resData = fetchUserProfile.data.response.data || null
        if (resData?.session_id) {
          const sessionDocRef = db.doc(`sessions/${resData.session_id}`)
          sessionDocRef.onSnapshot(
            (snapshot) => {
              let userData = snapshot.data()
              userData.token = resData.token
              setUser(userData)
            },
            (error) => {
              console.error("Error fetching document:", error)
            }
          )
        }
      } catch (error) {
        console.error("Error getting User Profile.", error)
        setUser(null)
      }
    }

    const getAnonymousUser = async () => {
      const ANONYMOUS_USER_URL = services.userURL()
      const ANONYMOUS_USER_ENDPOINT = `${ANONYMOUS_USER_URL}/token/`
      try {
        const fetchAnonymousUser = await axios.get(ANONYMOUS_USER_ENDPOINT)
        const resData = fetchAnonymousUser.data.response.data || null
        resData.role_id = "anonymous"
        setUser(resData)
      } catch (error) {
        console.error("Error getting Anonymous User.", error)
        setUser(null)
      }
    }

    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        getUserProfile(authUser.uid)
      } else {
        getAnonymousUser()
      }
    })

    return () => unsubscribe()
  }, [])

  if (!user) return null

  const createApolloClient = () => {
    const GRAPHQL_SERVER_URL = services.graphQLURL()

    const setAuthorizationLink = setContext(() => ({
      headers: { Authorization: `Bearer ${user.token}` },
    }))

    const removeTypenameLink = removeTypenameFromVariables()
    const httpLink = new HttpLink({ uri: GRAPHQL_SERVER_URL })

    const link = from([
      removeTypenameLink,
      setAuthorizationLink.concat(httpLink),
    ])

    return new ApolloClient({
      cache: new InMemoryCache(),
      link,
    })
  }

  const apolloClient = createApolloClient()

  return (
    <Router>
      <UserContext.Provider value={user}>
        <ApolloProvider client={apolloClient}>
          <Layout>
            {user?.session_id ? (
              <RoutesBuilder />
            ) : (
              <Routes>
                <Route
                  path={ROUTES.REGISTRATION_FORM}
                  element={<Registration />}
                />
                <Route path={ROUTES.THANK_YOU} element={<ThankYou />} />
                <Route path={ROUTES.ERROR} element={<ErrorPage />} />
                <Route path="*" element={<Login />} />
              </Routes>
            )}
          </Layout>
        </ApolloProvider>
      </UserContext.Provider>
    </Router>
  )
}

export default App
