import * as Yup from "yup"
import * as types from "../validators"

export default function yupValidationSchema(fields, idTemplate) {
  const yupSchema = fields.reduce((acc, field) => {
    let schema = acc

    const isPassword = field.field_type === "password"
    const isRegistration = idTemplate === "participant"

    if (isPassword && isRegistration) {
      return types.type_text(field, schema)
    }

    const skipValidation =
      field.field_disabled_on_edit || !field.field_fetchable

    if (skipValidation) return schema

    switch (field.field_type) {
      case "text":
      case "textarea_rich":
      case "dropdown":
      case "sys":
      case "date":
        return types.type_text(field, schema)
      case "number":
        return types.type_number(field, schema)
      case "conditional_dropdown":
        return types.type_conditional_dropdown(field, schema)
      default:
        return schema
    }
  }, Yup.object())

  return yupSchema
}
