import React, { useContext } from "react"
import { useQuery, gql } from "@apollo/client"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Button } from "primereact/button"
import { useNavigate } from "react-router-dom"
import { UserContext } from "../../context/userContext"

import { LoaderMedium } from "../../components/Loaders"
import gATracking from "../../helpers/gaTracking"
import getActualTime from "../../helpers/getActualTime"
import propTypes from "prop-types"
const GET_LESSONS = gql`
  query GetLessons {
    lessons {
      id
      title
      description
      resources {
        id
      }
    }
  }
`
const ActionButton = ({ rowData, date, gaTrackingParams, navigate }) => {
  return (
    <Button
      label="Edit"
      className="p-button-primary"
      style={{
        backgroundColor: "var( --branded-dark-blue)",
        borderColor: "var(--branded-dark-blue)",
      }}
      onClick={() => {
        gaTrackingParams.event = "edit"
        gaTrackingParams.payload = {
          content_id: rowData.id,
          content_type: "Course",
          timestamp: date,
        }
        gATracking(gaTrackingParams)
        navigate(`/edit/lesson/${rowData.id}`)
      }}
    />
  )
}

ActionButton.propTypes = {
  rowData: propTypes.object,
  date: propTypes.string,
  gaTrackingParams: propTypes.object,
  navigate: propTypes.func,
}
export default function ListLessons() {
  const navigate = useNavigate()
  const userCtx = useContext(UserContext)
  const date = getActualTime()
  const { loading, error, data } = useQuery(GET_LESSONS, {
    fetchPolicy: "network-only",
  })

  if (loading) return <LoaderMedium />
  if (error) return <div>Error! {error.message}</div>

  const lessons = data.lessons

  const gaTrackingParams = {
    event: "",
    userCtx,
    payload: {},
  }
  /*End Google Analytics tracking*/
  const renderActionButton = (rowData) => (
    <ActionButton
      rowData={rowData}
      date={date}
      gaTrackingParams={gaTrackingParams}
      navigate={navigate}
    />
  )
  return (
    <div>
      <h1 className="flex align-content-center gap-2">
        List of Lessons
        <Button
          icon="pi pi-plus"
          rounded
          style={{
            backgroundColor: "var( --branded-dark-blue)",
            borderColor: "var(--branded-dark-blue)",
          }}
          onClick={() => navigate("/add/lesson")}
          severity="success"
          aria-label="Add"
        />
      </h1>
      <DataTable
        value={lessons}
        paginator
        rows={10}
        resizableColumns
        showGridlines
        scrollHeight="flex"
        className="w-full	"
        style={{ tableLayout: "fixed", maxWidth: "100%" }}
      >
        <Column
          sortable
          field="title"
          header="Title"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "75px", wordWrap: "break-word" }}
        />
        <Column
          field="description"
          header="Description"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "250px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          header="Number of Resources"
          headerStyle={{ whiteSpace: "pre-line" }}
          body={(rowData) => rowData.resources.length}
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "50px", wordWrap: "break-word" }}
        />
        <Column
          header="Actions"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "50px", wordWrap: "break-word" }}
          body={renderActionButton}
        />
      </DataTable>
    </div>
  )
}
