import React, { useContext } from "react"
import { useQuery, gql } from "@apollo/client"
import { useNavigate } from "react-router-dom"
import { LoaderMedium } from "../../components/Loaders"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Button } from "primereact/button"
import { UserContext } from "../../context/userContext"
import gATracking from "../../helpers/gaTracking"
import getActualTime from "../../helpers/getActualTime"

const GET_RESOURCES = gql`
  {
    resources {
      id
      type
      name
      avgTime
    }
  }
`

// Function to convert milliseconds to minutes
const convertMillisToMinutes = (milliseconds) => {
  const minutes = milliseconds / (1000 * 60)
  return minutes.toFixed(2) // Displaying up to 2 decimal places
}

export default function SharedResources() {
  const navigate = useNavigate()
  const userCtx = useContext(UserContext)
  const date = getActualTime()
  const { loading, data } = useQuery(GET_RESOURCES, {
    fetchPolicy: "network-only",
  })

  if (loading) return <LoaderMedium />

  const resources = data?.resources || []

  /*Google Analytics tracking*/
  const gaTrackingParams = {
    event: "",
    userCtx,
    payload: {},
  }
  /*End Google Analytics tracking*/

  const action = ({ id }) => {
    return (
      <Button
        label="Edit"
        onClick={() => {
          gaTrackingParams.event = "edit"
          gaTrackingParams.payload = {
            content_id: id,
            content_type: "Resource",
            timestamp: date,
          }
          gATracking(gaTrackingParams)

          navigate(`/edit/resource/${id}`)
        }}
      />
    )
  }

  return (
    <div>
      <h1>Shared Resources</h1>
      <DataTable
        value={resources}
        resizableColumns
        paginator
        rows={10}
        showGridlines
        scrollHeight="flex"
        className="w-full"
        style={{ tableLayout: "fixed", maxWidth: "100%" }}
      >
        <Column
          sortable
          field="type"
          header="Type"
          className="white-space-normal overflow-hidden"
          style={{ maxWidth: "50px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          field="name"
          header="Name"
          className="white-space-normal overflow-hidden"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          field="avgTime"
          header="Average Time (mins)"
          className="white-space-normal overflow-hidden"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
          body={(rowData) => convertMillisToMinutes(rowData.avgTime)}
        />
        <Column
          body={(row) => action(row)}
          header="Action"
          className="white-space-normal overflow-hidden"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
      </DataTable>
    </div>
  )
}
