import * as Yup from "yup"

export default function validation_text(field, schema) {
  const isRequired = field.field_validation?.required?.[0] === "true"
  const requiredTxt = field.field_validation?.required?.[1]
  if (isRequired) {
    return schema.shape({
      [field.field_name]: Yup.string().required(requiredTxt || "Required"),
    })
  }
  return schema.shape({
    [field.field_name]: Yup.string(),
  })
}
