import React, { useContext, useState, useEffect } from "react"
import { useQuery } from "@apollo/client"
import { Link, useLocation } from "react-router-dom"
import _ from "lodash"

import { UserContext } from "../context/userContext"
import useUserRoleMenu from "./hooks/useUserRoleMenu"

import ROUTES from "../components/Routes/routesConstants"
import Assessment from "../pages/Assessments/Create"

import { Menubar } from "primereact/menubar"
import { Avatar } from "primereact/avatar"

import { LoaderMedium } from "../components/Loaders"
import styles from "../styles/Header.module.css"
import logo from "../assets/images/flower-logo-f.gif"

import avatarImg from "../assets/images/user-icon.jpg"
//GA
import gATracking from "../helpers/gaTracking"

import { GET_STAFF_IMG, GET_PARTICIPANTS_IMG } from "./queries/queryHeader"

export default function Header() {
  const userCtx = useContext(UserContext)
  const [assessment, setAssessment] = useState(false)
  const [staffImage, setStaffImage] = useState(null)
  const [participantImage, setParticipantImage] = useState(null)
  const location = useLocation()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    if (params.get("assessment") === "active") {
      setAssessment(true)
    }
  }, [location.search])

  //GA Tracking
  const gaTrackingParams = {
    event: "button_click",
    userCtx,
    payload: {},
  }
  // End GA Tracking
  const model = useUserRoleMenu(setAssessment) // Use the custom hook to get the menu based on user role

  const {
    loading,
    data,
    refetch: refetchStaffImage,
  } = useQuery(GET_STAFF_IMG, {
    variables: { staff_id: userCtx.session_id },
    fetchPolicy: "network-only",
    skip: !userCtx.session_id,
  })

  const {
    loading: loadingParticipant,
    data: dataParticipant,
    refetch: refetchParticipantImage,
  } = useQuery(GET_PARTICIPANTS_IMG, {
    variables: { participant_id: userCtx.session_id },
    fetchPolicy: "network-only",
    skip: !userCtx.session_id,
  })

  // Refetch the images after profile update (mocking profile update)
  useEffect(() => {
    if (userCtx.role_id === "auth-user" && dataParticipant) {
      refetchParticipantImage()
    } else if (userCtx.role_id !== "auth-user" && data) {
      refetchStaffImage()
    }
  }, [
    data,
    dataParticipant,
    userCtx.role_id,
    refetchStaffImage,
    refetchParticipantImage,
  ])

  useEffect(() => {
    if (data && userCtx.role_id !== "auth-user") {
      const img = new Image()
      img.onload = () =>
        setStaffImage(data.staff.staff_profile_image_link_32x32 || null)
      img.src = data.staff.staff_profile_image_link_32x32 || ""
    }
    if (dataParticipant && userCtx.role_id === "auth-user") {
      const img = new Image()
      img.onload = () =>
        setParticipantImage(
          dataParticipant.participant.participant_profile_image_link_32x32 ||
            null
        )
      img.src =
        dataParticipant.participant.participant_profile_image_link_32x32 || ""
    }
  }, [data, dataParticipant, userCtx.role_id])

  if (loading || loadingParticipant) return <LoaderMedium />

  const start = (
    <Link to={ROUTES.HOMEPAGE} className={styles.logo}>
      <img src={logo} alt="LifeXT" className={styles.logo} />
    </Link>
  )
  const handleGatracker = (params) => {
    gaTrackingParams.event = "button_click"
    gaTrackingParams.payload = {
      button_id: params.button_id,
      button_text: params.button_text,
      button_type: params.button_type,
      button_location: params.button_location,
    }
    gATracking(gaTrackingParams)
  }
  const end = () => {
    if (_.isEmpty(userCtx.session_id)) return null
    if (userCtx.role_id === "auth-user") {
      return (
        <div className="flex align-items-center gap-2">
          Welcome,
          <Link
            id="participantProfileLink"
            to={`participant/profile`}
            className="text-green-600"
            onClick={(e) => {
              const idButton = e.currentTarget.id

              handleGatracker({
                button_id: idButton,
                button_text: userCtx.name_full,
                button_type: "menu_link",
                button_location: "participant_header",
              })
            }}
          >
            <strong>{userCtx.name_full}</strong>
          </Link>
          <Avatar
            image={participantImage || avatarImg}
            shape="circle"
            style={{ objectFit: "cover" }}
          />
        </div>
      )
    } else {
      return (
        <div className="flex align-items-center gap-2">
          Welcome,
          <Link
            id="staffProfileLink"
            to={`staff-user/profile/${userCtx.session_id}`}
            className="text-green-600"
            onClick={(e) => {
              const idButton = e.currentTarget.id
              handleGatracker({
                button_id: idButton,
                button_text: userCtx.name_full,
                button_type: "menu_link",
                button_location: "staff_header",
              })
            }}
          >
            <strong>{userCtx.name_full}</strong>
          </Link>
          <Avatar
            image={staffImage || avatarImg}
            shape="circle"
            style={{ objectFit: "cover" }}
          />
        </div>
      )
    }
  }

  return (
    <>
      <header className="flex">
        <div className={styles.headerContainer}>
          <Menubar model={model} start={start} end={end()} />
        </div>
      </header>
      <Assessment visible={assessment} setVisible={setAssessment} />
    </>
  )
}
