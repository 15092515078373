import app from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import * as storage from "firebase/storage"

const firebaseConfigProd = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY_PROD,
  authDomain: "ths-wb-prod.firebaseapp.com",
  projectId: "ths-wb-prod",
  storageBucket: "ths-wb-prod.appspot.com",
  messagingSenderId: "800607469844",
  appId: "1:800607469844:web:6370a37bf590273f790201",
  measurementId: "G-3KZ9E1L6SS",
}

const firebaseConfigDev = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY_DEV,
  authDomain: "ths-wb-dev.firebaseapp.com",
  projectId: "ths-wb-dev",
  storageBucket: "ths-wb-dev.appspot.com",
  messagingSenderId: "705785829893",
  appId: "1:705785829893:web:9302ff32046e16bb7a5a6c",
  measurementId: "G-CB08B2J0CE",
}

const isProd = process.env.REACT_APP_STAGE === "prod"

const firebaseConfig = isProd ? firebaseConfigProd : firebaseConfigDev

app.initializeApp(firebaseConfig)

const db = app.firestore()
const auth = app.auth()

export { db, auth, storage, firebaseConfig }
