const ENTITY = {}

const DEF = {}

const DELETE = {}

const ADD = {}

const EDIT = {}

const SET = {}

const GET = {
  ROUTES_PERMISSIONS: "routes",
  ADMIN_USER_PROFILE: "sessions",
}

export { ENTITY, DEF, DELETE, ADD, EDIT, SET, GET }
