import React, { useContext, useEffect, useRef, useState } from "react"
import { UserContext } from "../../../context/userContext"
import { useFormikContext } from "formik"
import { LoaderMedium } from "../../../components/Loaders"
import { useQuery, gql } from "@apollo/client"

// PR
import { Button } from "primereact/button"
import { FileUpload } from "primereact/fileupload"
import { ProgressBar } from "primereact/progressbar"
import { Toast } from "primereact/toast"

// Hooks
import useStorageFileUploader from "../../../hooks/useStorageFileUploader"

import propTypes from "prop-types"

const GET_STAFF_IMG = gql`
  query Staff($staff_id: ID!) {
    staff(staff_id: $staff_id) {
      staff_profile_image_link_400x400
    }
  }
`

const GET_PARTICIPANTS_IMG = gql`
  query Participant($participant_id: ID!) {
    participant(participant_id: $participant_id) {
      participant_profile_image_link_400x400
    }
  }
`

export default function TypeFile({ field, isEditMode }) {
  const formik = useFormikContext()
  const userCtx = useContext(UserContext)
  const toast = useRef(null)
  const [initialLoad, setInitialLoad] = useState(true)
  const [isImageUploaded, setIsImageUploaded] = useState(false)

  const STORAGE_PATH = "profile_photo/"
  const DELETE_PATH = "processed_photo/rezise"
  const FILE_TYPE = "image"
  const FILE_SIZE = 1e7 // 10Mb
  const CURRENT_GS = formik.values[field.field_name] || ""
  const [customUploadHandler, removeHandler, clear, progress, gsRef] =
    useStorageFileUploader(STORAGE_PATH, FILE_TYPE, CURRENT_GS, DELETE_PATH)

  const isStaff =
    userCtx.role_id === "ths-admin" || userCtx.role_id === "staff-user"

  useEffect(() => {
    formik.setFieldValue(field.field_name, gsRef)
  }, [gsRef])

  const { loading, error, data } = useQuery(
    isStaff ? GET_STAFF_IMG : GET_PARTICIPANTS_IMG,
    {
      variables: isStaff
        ? { staff_id: userCtx.session_id }
        : { participant_id: userCtx.session_id },
      fetchPolicy: "network-only",
      skip: !userCtx.session_id,
    }
  )

  useEffect(() => {
    if (initialLoad) {
      setIsImageUploaded(CURRENT_GS !== "profile_photo/default.jpg")
      setInitialLoad(false)
    }
  }, [CURRENT_GS, initialLoad])

  useEffect(() => {
    if (progress === 100) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Image uploaded successfully",
        life: 3000,
      })
    }
  }, [progress])

  const handleUpload = async (event) => {
    await customUploadHandler(event)
  }

  if (loading) return <LoaderMedium />
  if (error) return <div>Error! {error.message}</div>

  const imageLink = isStaff
    ? data?.staff?.staff_profile_image_link_400x400
    : data?.participant?.participant_profile_image_link_400x400

  const showCurrentImage = isImageUploaded && imageLink

  if (showCurrentImage) {
    return (
      <div className="flex flex-column gap-3">
        <Toast ref={toast} />
        <p>Coach member profile image.</p>
        {
          <img
            src={imageLink}
            alt="profile"
            style={{ width: "50%", borderRadius: "50%" }}
          />
        }
        <Button
          label="Remove and Replace"
          outlined
          className=" w-4 "
          onClick={async () => {
            await removeHandler()
            formik.setFieldValue(field.field_name, "")
            setIsImageUploaded(false)
            toast.current.show({
              severity: "info",
              summary: "Info",
              detail: "Image removed successfully",
              life: 3000,
            })
          }}
        />
      </div>
    )
  }

  return (
    <div>
      <Toast ref={toast} />
      <label>{field.field_label}</label>
      <label htmlFor="fileUpload">
        <br />
        Choose image file and then select Upload to add profile image.
      </label>
      <div>
        <FileUpload
          customUpload
          uploadHandler={handleUpload}
          onRemove={removeHandler}
          onClear={clear}
          accept="image/*"
          maxFileSize={FILE_SIZE}
        />
        <ProgressBar value={progress} style={{ margin: "10px 0" }} />
      </div>
    </div>
  )
}

TypeFile.propTypes = {
  field: propTypes.object,
  isEditMode: propTypes.bool,
}
