import React, { useRef, useEffect, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { useQuery, useMutation, gql } from "@apollo/client"
import { LoaderMedium } from "../../components/Loaders"
import { UserContext } from "../../context/userContext"

// PrimeReact
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { SplitButton } from "primereact/splitbutton"
import { Button } from "primereact/button"
import { Toast } from "primereact/toast"

import gATracking from "../../helpers/gaTracking"
import getActualTime from "../../helpers/getActualTime"
const GET_ORGANIZATIONS = gql`
  query Organizations {
    organizations: organizations {
      organization_id
      organization_name
      organization_account_name
      organization_cohort_number
      organization_contact_name
      organization_contact_email
      organization_contact_phone
      organization_status
    }
  }
`

const DELETE_ORGANIZATION = gql`
  mutation deleteOrganization($organization_id: ID!) {
    deleteOrganization(organization_id: $organization_id) {
      message
    }
  }
`

export default function OrganizationList() {
  const { loading, error, data } = useQuery(GET_ORGANIZATIONS, {
    fetchPolicy: "network-only",
  })

  const [deleteOrganization, { data: mutationData, error: mutationError }] =
    useMutation(DELETE_ORGANIZATION)

  const navigate = useNavigate()
  const toast = useRef(null)
  const userCtx = useContext(UserContext)
  const date = getActualTime()
  useEffect(() => {
    if (mutationData?.deleteOrganization?.message) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: mutationData.deleteOrganization.message,
        life: 3000,
      })
    } else if (mutationError) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: mutationError.message,
        life: 3000,
      })
    }
  }, [mutationData, mutationError])

  if (loading) return <LoaderMedium />
  if (error) return <div>Error! {error.message}</div>

  const organizations = data.organizations

  /*Google Analytics tracking*/
  const gaTrackingParams = {
    event: "",
    userCtx,
    payload: {},
  }
  /*End Google Analytics tracking*/

  const action = (rowData) => {
    const { organization_id } = rowData
    const items = [
      {
        label: "Delete",
        icon: "pi pi-trash",
        command: () => {
          //GA

          gaTrackingParams.event = "deleted"
          gaTrackingParams.payload = {
            organization_id: rowData.organization_id,
            content_id: rowData.organization_id,
            content_type: "organization",
            timestamp: date,
          }
          gATracking(gaTrackingParams)
          //GA

          deleteOrganization({
            variables: { organization_id },
            refetchQueries: [{ query: GET_ORGANIZATIONS }],
          })
        },
      },
    ]
    return (
      <SplitButton
        label="Edit"
        model={items}
        onClick={() => {
          gaTrackingParams.event = "deleted"
          gaTrackingParams.payload = {
            organization_id: rowData.organization_id,
            content_id: organization_id,
            content_type: "organization",
            timestamp: date,
          }
          gATracking(gaTrackingParams)

          navigate(`/form/edit/organization/1/${organization_id}`)
        }}
      />
    )
  }

  return (
    <div className="p-col-12 p-md-3">
      <Toast ref={toast} />
      <div className="flex gap-5 mb-2 align-items-center mb-4">
        <h1 className="mt-2">Organization List</h1>
        <Button
          className="mb-2"
          icon="pi pi-plus"
          rounded
          onClick={() => navigate("/form/organization/1")}
          style={{
            backgroundColor: "var(--branded-dark-blue)",
            borderColor: "var(--branded-dark-blue)",
          }}
          severity="success"
          aria-label="Add"
        />
      </div>
      <DataTable
        value={organizations}
        paginator
        rows={10}
        resizableColumns
        showGridlines
        scrollHeight="flex"
        className="w-full	"
        style={{ tableLayout: "fixed", maxWidth: "100%" }}
      >
        <Column
          sortable
          filter
          field="organization_name"
          header="Name"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          filter
          field="organization_account_name"
          header="Account"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "150px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          field="organization_contact_name"
          header="Contact Name"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          filter
          field="organization_contact_email"
          header="Email"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "250px", wordWrap: "break-word" }}
        />
        <Column
          field="organization_contact_phone"
          header="Phone"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          field="organization_cohort_number"
          header="Total Cohorts"
          headerStyle={{ whiteSpace: "pre-line" }}
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          filter
          field="organization_status"
          header="Status"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
        <Column
          body={(rowData) => action(rowData)}
          header="Action"
          className="white-space-normal overflow-hidden"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
      </DataTable>
    </div>
  )
}
