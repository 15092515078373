import React from "react"

export default function Denied() {
  return (
    <div>
      <h1>Wait!, you are not supposed to be Here!</h1>
      <p>Sorry, you don't have permission to view this page.</p>
      <a href="/">Go to Home</a>
    </div>
  )
}
